import heroBg from "~assets/images/backgrounds/marketplace.webp";
import MarketplaceFilters from "./MarketplaceFilters";
import MarketplaceItems from "./MarketplaceItems";

const Marketplace = ({
  productsOnSale,
  isLoading,
  setFilters,
  setSortBy,
  filters,
  clearFilters,
  hasNextPage,
  fetchNextPage,
  isFetching,
  isFetchingNextPage,
  isPlaceholderData,
  totalProducts,
  oracles,
  brands
}) => (
  <div id="marketplace" className="container wide light">
    <section className="hero" style={{ "--bg-image": `url(${heroBg})` }}>
      <h2>Altr Marketplace</h2>
      <p>Discover Timeless Luxury: Secure Your Treasures on the Blockchain</p>
    </section>
    <div className="content">
      <MarketplaceFilters filters={filters} setFilters={setFilters} clearFilters={clearFilters} oracles={oracles} brands={brands} />
      <MarketplaceItems
        productsOnSale={productsOnSale}
        totalProducts={totalProducts}
        isLoading={isLoading}
        setSortBy={setSortBy}
        setFilters={setFilters}
        filters={filters}
        clearFilters={clearFilters}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
        isFetching={isFetching}
        isFetchingNextPage={isFetchingNextPage}
        isPlaceholderData={isPlaceholderData}
        oracles={oracles}
        brands={brands}
      />
    </div>
  </div>
);

export default Marketplace;
