import React, { useEffect, useState } from "react";
import { useApp, usePurchaseData } from "~hooks";
import { NFTIconNote, NFTCheckout, NFTOverlay } from "~components";
import { formatStablecoin } from "~utils/helpers";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { blockchainHooks } from "~hooks/blockchainHooks";
import { handleError } from "~utils/error";
import { usePublicClient, useWalletClient } from "wagmi";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlaySellWholeFromBuyOrder = ({ nft, activeOrder }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const {
    setOverlayCompletionData,
    activeOverlay,
    userData: {
      balances: { maticBalance }
    }
  } = useApp();

  const { data: purchaseData, approved, setApproved, reset, updateAll } = usePurchaseData();

  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();

  const { trader } = useExternalIntegrations();
  const traderSdk = trader(publicClient, walletClient);

  const { useManage0xNftAllowance } = blockchainHooks();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [executing, setExecuting] = useState(false);

  // ---------------------------------------------------------------------------
  // lifecycle

  const { refetch } = useManage0xNftAllowance(nft?.enrichedProduct?.nftData, `ERC721`, `1`, setApproved, activeOverlay === `NFTOverlaySellWholeFromBuyOrder`);

  useEffect(() => {
    if (activeOrder && activeOverlay === `NFTOverlaySellWholeFromBuyOrder`) {
      const price = formatStablecoin(activeOrder?.erc20TokenAmount, true);
      const expiry = activeOrder?.order?.expiry;
      updateAll({ price, expiry });
    }
  }, [activeOrder?.erc20TokenAmount, activeOrder?.order?.expiry, activeOverlay, executing]);

  // ---------------------------------------------------------------------------
  // methods

  const executeApproval = async () => {
    if (!nft?.enrichedProduct?.nftData || !activeOrder || executing) {
      return () => {};
    }

    setExecuting(true);
    // @externalintegration
    try {
      await traderSdk.approveTokenOrder(activeOrder);
    } catch (e) {
      handleError(e, setOverlayCompletionData, maticBalance?.value);
      console.error(e);
    }

    setExecuting(false);

    return null;
  };

  const sell = async () => {
    if (!nft?.enrichedProduct?.product || !activeOrder || executing) {
      return () => {};
    }

    setExecuting(true);

    try {
      await traderSdk.fillOrder(activeOrder);
      reset();

      setOverlayCompletionData({
        icon: `check`,
        heading: `Deal closed successfully`,
        body: `Congratulation on accepting the offer and closing the deal.`
      });
    } catch (e) {
      handleError(e, setOverlayCompletionData, maticBalance?.value);
      console.error(e);
    }

    setExecuting(false);

    reset();
  };

  // ---------------------------------------------------------------------------
  // render

  if (!nft?.enrichedProduct) {
    return null;
  }

  return (
    <NFTOverlay id="NFTOverlaySellWholeFromBuyOrder" heading="Sell From Buy Orders" nft={nft} sidebarMode="wholeFromBuyOrder">
      <NFTCheckout
        className="nftOverlayGroup"
        heading="Sell Order"
        finalButtonText="Sell"
        subheading={`Total includes a ${nft?.enrichedProduct?.nftData?.takerFee} platform fee, deducted upon order fulfillment.`}
        subheadingVisible={parseFloat(nft?.enrichedProduct?.nftData?.takerFee.split(`%`)[0]) > 0}
        nft={nft}
        data={purchaseData}
        execute={sell}
        executeApproval={executeApproval}
        approved={approved}
        onReset={reset}
        refetch={refetch}
        type="whole"
        approveLoading={executing && !approved}
        actionLoading={executing && approved}
      />

      {!approved && (
        <div className="nftOverlayGroup">
          <NFTIconNote
            background="rgba(255, 255, 255, 0.4)"
            fontClass="caption"
            svg="alert"
            text="By clicking this button, you are authorizing the smart contract to initiate a blockchain transaction and process funds to the seller on your behalf. smart contract to transfer nfts to the seller on your behalf."
          />
        </div>
      )}
    </NFTOverlay>
  );
};

export default NFTOverlaySellWholeFromBuyOrder;
