/* eslint-disable camelcase */
import React, { createContext, useEffect, useState, useMemo } from "react";
import { globalHistory } from "@reach/router";
import { useKeyPress } from "~hooks";
import * as disableScrollStyles from "~styles/disableScroll.module.scss";
import { useAccount } from "wagmi";
import LoginService from "~services/auth";
import useKYC from "~hooks/useKYC";
import altrLoginAPI from "~hooks/altrLoginApi";
import { web3AuthInstance } from "~layouts";
import { blockchainHooks } from "~hooks/blockchainHooks";

export const AppContext = createContext({});

/** ============================================================================
 * @context
 * Global application data.
 */
const AppProvider = ({ children }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const escKeyPressed = useKeyPress(`Escape`);
  const { updateUserProfile, refreshProfileData } = altrLoginAPI();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [connectorActive, setConnectorActive] = useState(false);
  const [emailSetterActive, setEmailSetterActive] = useState(false);
  const [pathname, setPathname] = useState(null);
  const [footerInView, setFooterInView] = useState(false);
  const [userIsLoggedIn, setUserIsLoggedIn] = useState(false);
  const [reloadProfileData, setReloadProfileData] = useState(false);
  const [videoPlayerSource, setVideoPlayerSource] = useState(``);
  const [isVideoPlayerVisible, setIsVideoPlayerVisible] = useState(false);
  const [navbarLocationText, setNavbarLocationText] = useState({
    top: ``,
    bottom: ``
  });
  const [activeOverlay, setActiveOverlay] = useState(null);
  const [overlayActivityData, setOverlayActivityData] = useState(null);
  const [overlayProcessingData, setOverlayProcessingData] = useState(null);
  const [overlayCompletionData, setOverlayCompletionData] = useState(null);
  const [userData, setUserData] = useState({ user: null, address: null, balances: { maticBalance: null, usdtBalance: null, lcdBalance: null } });
  const [isUserDataLoading, setIsUserDataLoading] = useState(false);
  const [orderCanceled, setOrderCanceled] = useState(false);
  const [wrongChainOverlayData, setWrongChainOverlayData] = useState(null);
  const [emailTrigger, setEmailTrigger] = useState(false);
  const [disableScroll, setDisableScroll] = useState(false);
  const [raffleId, setRaffleId] = useState(null);
  const [raffleReferralCode, setRaffleReferralCode] = useState(null);
  const [kycData, setKycData] = useState({
    sessionId: null,
    status: null,
    kycEnabled: null
  });
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined
  });

  const { useGetNativeTokenBalance, useGetUSDTBalance, useGetLCDBalance } = blockchainHooks();

  // ---------------------------------------------------------------------------
  // lifecycle
  const { address, isConnected, status, connector } = useAccount();
  const { data: maticBalance, refetch: refetchMatic } = useGetNativeTokenBalance();
  const { data: usdtBalance, refetch: refetchUSDT } = useGetUSDTBalance();
  const { data: lcdBalance, refetch: refetchLCD } = useGetLCDBalance();

  useEffect(() => {
    setUserData((prev) => ({
      ...prev,
      balances: {
        maticBalance,
        usdtBalance,
        lcdBalance,
        refetch: () => {
          refetchMatic();
          refetchLCD();
          refetchUSDT();
        }
      }
    }));
  }, [maticBalance?.value, usdtBalance?.value, lcdBalance?.value]);

  // Track window resize
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      });
    }

    window.addEventListener(`resize`, handleResize);

    handleResize();

    return () => window.removeEventListener(`resize`, handleResize);
  }, []);

  // Disable page scroll
  useEffect(() => {
    if (isVideoPlayerVisible || connectorActive || activeOverlay || disableScroll) {
      document.body.classList.add(disableScrollStyles.disableScroll);
    } else {
      document.body.classList.remove(disableScrollStyles.disableScroll);
    }
  }, [isVideoPlayerVisible, connectorActive, activeOverlay, disableScroll]);

  // Remove page scroll disable whenever the route changes
  useEffect(() => {
    document.body.classList.remove(disableScrollStyles.disableScroll);
  }, [pathname]);

  // Update pathname
  useEffect(() => {
    if (typeof window !== `undefined` && window?.location?.pathname) {
      setPathname(window.location.pathname);
    }

    return globalHistory.listen(({ location }) => {
      setPathname(location.pathname);
    });
  }, []);

  useEffect(() => {
    setActiveOverlay(null);
  }, [escKeyPressed]);

  useEffect(() => {
    if (!activeOverlay) {
      setOverlayActivityData(null);
      // setOverlayCompletionData(null);
      setOverlayProcessingData(null);
    }
  }, [activeOverlay]);

  useEffect(() => {
    const updateUserData = async () => {
      const loginService = new LoginService();
      const user = loginService.getUser();

      if (userIsLoggedIn && !user?.emailAddress && connector?.id === `web3auth`) {
        const userInfo = await web3AuthInstance.getUserInfo();
        if (userInfo?.email && user?.token) {
          await updateUserProfile(userInfo?.email, userInfo?.name);
          await refreshProfileData();
          setReloadProfileData(!reloadProfileData);
        }
      }

      setUserData((prev) => ({ ...prev, user, address, isConnected }));
    };

    setIsUserDataLoading(true);
    updateUserData()
      .catch((e) => console.error(e))
      .finally(() => setIsUserDataLoading(false));
  }, [userIsLoggedIn, address, isConnected, status, reloadProfileData, userData?.user?.token]);

  const { kycSession, kycStatus, kycTestMode } = useKYC(userData);
  useEffect(() => {
    setKycData({ kycSession, kycStatus, kycTestMode });
  }, [kycSession, kycStatus, kycTestMode]);

  // ---------------------------------------------------------------------------
  // render

  const contextProps = useMemo(() => ({
    connectorActive,
    setConnectorActive,
    emailSetterActive,
    setEmailSetterActive,
    pathname,
    footerInView,
    setFooterInView,
    userIsLoggedIn,
    setUserIsLoggedIn,
    reloadProfileData,
    setReloadProfileData,
    videoPlayerSource,
    setVideoPlayerSource,
    isVideoPlayerVisible,
    setIsVideoPlayerVisible,
    navbarLocationText,
    setNavbarLocationText,
    //
    activeOverlay,
    setActiveOverlay,
    overlayActivityData,
    setOverlayActivityData,
    overlayProcessingData,
    setOverlayProcessingData,
    overlayCompletionData,
    setOverlayCompletionData,
    userData,
    setUserData,
    isUserDataLoading,
    orderCanceled,
    setOrderCanceled,
    wrongChainOverlayData,
    setWrongChainOverlayData,
    emailTrigger,
    setEmailTrigger,
    disableScroll,
    setDisableScroll,
    kycData,
    setKycData,
    windowSize,
    raffleId,
    setRaffleId,
    raffleReferralCode,
    setRaffleReferralCode
  }));

  return <AppContext.Provider value={contextProps}>{children}</AppContext.Provider>;
};

export default AppProvider;
