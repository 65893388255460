import { useState } from "react";
import Button from "~components/Common/Button";
import PriceWidget from "~components/Product/PriceWidget";
import useApp from "~hooks/useApp";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { handleError } from "~utils/error";
import cn from "classnames";
import { usePublicClient, useWalletClient } from "wagmi";
import { useParseTraderOrder } from "~hooks/useParseTraderOrder";

const OfferOverview = ({ sellOrder }) => {
  const [executing, setExecuting] = useState(false);

  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { trader } = useExternalIntegrations();
  const {
    userData: {
      balances: { maticBalance }
    },
    setOverlayCompletionData
  } = useApp();

  const {
    price: { displayPrice, displayPriceWithMakerFee },
    eta
  } = useParseTraderOrder(sellOrder.order);

  const handleCancelOffer = async () => {
    setExecuting(true);
    const traderSdk = trader(publicClient, walletClient);
    try {
      await traderSdk.cancelOrder(sellOrder);
      setOverlayCompletionData({
        icon: `check`,
        heading: `Order cancelled`
      });
    } catch (error) {
      handleError(error, setOverlayCompletionData, maticBalance?.value);
    }
    setExecuting(false);
  };

  return (
    <section className="saleOffer">
      <h3>Your Sale Offer</h3>
      <p>Review your current sale offer details below. Use the cancel button to withdraw your offer before the expiration time.</p>
      <div className="saleOfferDetails">
        <dl>
          <dt>Current price</dt>
          <dd>
            <PriceWidget color="white" fontClass="h4" currencyVariant="secondary" displayPrice={displayPriceWithMakerFee} />
          </dd>
        </dl>
        <dl>
          <dt>After fees</dt>
          <dd>
            <PriceWidget color="white" fontClass="h4" currencyVariant="secondary" displayPrice={displayPrice} />
          </dd>
        </dl>
        <dl>
          <dt>Expiration</dt>
          <dd>{eta}</dd>
        </dl>
      </div>
      <Button className={cn(executing && `loading`)} variant="primaryTall" fluid onClick={handleCancelOffer}>
        Cancel Offer
      </Button>
    </section>
  );
};

export default OfferOverview;
