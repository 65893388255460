import { useEffect, useState } from "react";
import {
  BaseApiLoginWalletResponse,
  AuthenticationWalletCredential,
  AcceptedTermsAndConditionsVM,
  LoggedUserVM
} from "~dApp/models/ApiModel";
import LoginService from "~services/auth";
import { genericErrorCallback } from "~utils/helpers";
import * as Sentry from "@sentry/core";
import { CustomLoginData } from "~dApp/models/CustomLoginData";

const BASE_URL = process.env.GATSBY_LUCID_DB_URL;

const altrLoginAPI = () => {
  // ---------------------------------------------------------------------------
  // context / ref / state
  const loginService = new LoginService();

  // ---------------------------------------------------------------------------
  // graphql data

  // ---------------------------------------------------------------------------
  // methods

  const registerUser = async (
    registrationData: AuthenticationWalletCredential
  ) => {
    const userNonce = await walletRegister(registrationData);
    console.debug(
      `[debug] User ${registrationData.publicAddress} registered. Nonce ${userNonce}`
    );
    return userNonce;
  };

  const getNonceForOnboardedUser = async (
    publicAddress: string,
    preferredCulture: string,
    setCallback: React.Dispatch<React.SetStateAction<string>>
  ) => {
    const registrationData = new AuthenticationWalletCredential();
    registrationData.preferredCulture = preferredCulture;
    registrationData.publicAddress = publicAddress;

    let userNonce: string = await getUserWalletNonce(
      registrationData.publicAddress
    );

    if (!userNonce) {
      userNonce = await registerUser(registrationData);
    }

    console.debug(`[debug] ${publicAddress} Nonce ${userNonce}`);
    if (userNonce) {
      setCallback(userNonce);
    }
  };

  const checkResponseStatus = (response: Response) => {
    if (response.status == 499) {
      //FIXME: call api authclient/refreshtoken
      //FIXME: this check must be used in every authenticate endpoint api call
    }

    if (!response.ok) throw Error(response.statusText);
  };
  // ---------------------------------------------------------------------------
  // API methods

  /**
   * Get nonce
   */
  const getUserWalletNonce = async (walletAddress: string): Promise<string> => {
    let nonce = ``;

    try {
      const response = await fetch(
        `${BASE_URL}/api/Customer/GetUserWalletNonce?walletAddress=${walletAddress}`,
        {
          method: `GET`,
          cache: "no-cache",
          headers: { "Content-Type": `application/json` }
        }
      );
      checkResponseStatus(response);
      nonce = await response.text();
      return nonce;
    } catch (e) {
      console.error(
        `Problem retrieving nonce for address '${walletAddress}': `,
        e
      );
      throw e;
    }
  };

  /**
   * Wallet registration
   */
  const walletRegister = async (
    registrationData: AuthenticationWalletCredential
  ): Promise<string> => {
    let nonce = ``;

    try {
      const response = await fetch(`${BASE_URL}/api/Customer/WalletRegister`, {
        method: `POST`,
        cache: "no-cache",
        body: JSON.stringify(registrationData),
        headers: { "Content-Type": `application/json` }
      });
      checkResponseStatus(response);
      nonce = await response.text();
      return nonce;
    } catch (e) {
      console.error(
        `Problem in wallet registration for address '${registrationData.publicAddress}': `,
        e
      );
      throw e;
    }
  };

  /**
   * Wallet authentication
   */
  const walletAuthentication = async (
    authenticationData: AuthenticationWalletCredential
  ): Promise<BaseApiLoginWalletResponse> => {
    try {
      const response = await fetch(
        `${BASE_URL}/api/Customer/WalletAuthentication`,
        {
          method: `POST`,
          cache: "no-cache",
          body: JSON.stringify(authenticationData),
          headers: { "Content-Type": `application/json` }
        }
      );
      checkResponseStatus(response);
      const loginWalletResponse = Object.assign(
        new BaseApiLoginWalletResponse(),
        await response.json()
      );
      return loginWalletResponse;
    } catch (e) {
      console.error(
        `Problem in wallet authentication for address '${authenticationData.publicAddress}': `,
        e
      );
      throw e;
    }
  };

  /**
   * Check if is a valid Bearer token
   */
  const isAValidLoginToken = async (
    walletAddress: string,
    token: string
  ): Promise<boolean> => {
    if (!token || !walletAddress) {
      return false;
    }

    const authorizationHeader =
      loginService.getAuthorizationHeaderForLoggedUser();

    try {
      const response = await fetch(`${BASE_URL}/api/Auth/CheckLogin`, {
        method: `GET`,
        cache: "no-cache",
        headers: authorizationHeader
      });
      return response.ok;
    } catch (e) {
      console.error(`Problem in isAValidTokenLogin ${walletAddress}': `, e);
      throw e;
    }
  };

  /**
   * Update user session (refresh token, update accepted terms and conditions)
   */
  const updateUserSession = async (
    token: string,
    acceptedTermsAndConditions: AcceptedTermsAndConditionsVM
  ): Promise<LoggedUserVM> => {
    const headers = loginService.getAuthorizationHeaderForToken(token);
    headers["Content-Type"] = "application/json";
    headers["Accept"] = "application/json";

    try {
      const response = await fetch(`${BASE_URL}/api/Auth/UpdateUserSession`, {
        method: `POST`,
        body: JSON.stringify([acceptedTermsAndConditions]),
        headers: headers
      });
      checkResponseStatus(response);
      const loggedUserVM = Object.assign(
        new LoggedUserVM(),
        await response.json()
      );
      return loggedUserVM;
    } catch (e) {
      Sentry.captureMessage(`Problem in updateUserSession': ${e}`, "error");
      throw e;
    }
  };

  /**
   * Update user profile (email, name, TODO: optin, optout, marketing preferences)
   */
  const updateUserProfile = async (
    email: string,
    nickname: string
  ): Promise<CustomLoginData> => {
    //FIXME: define a VM with marketing preferences ecc
    var updatedData = {
      emailAddress: email,
      nickname: nickname
    };
    const userData: CustomLoginData = loginService.getUser();
    const headers = loginService.getAuthorizationHeaderForToken(userData.token);
    headers["Content-Type"] = "application/json";
    headers["Accept"] = "application/json";

    try {
      const response = await fetch(`${BASE_URL}/api/Auth/UpdateUserProfile`, {
        method: `POST`,
        body: JSON.stringify(updatedData),
        headers: headers
      });

      checkResponseStatus(response);
      let profileData = await response.json();
      const updatedUserData = Object.assign(userData, profileData);
      loginService.setUser(updatedUserData);
      return userData;
    } catch (e) {
      Sentry.captureMessage(`Problem in updateUserProfile': ${e}`, "error");
      throw e;
    }
  };

  /**
   * Update user profile (email, name, TODO: optin, optout, marketing preferences)
   */
  const refreshProfileData = async (): Promise<void> => {
    const userData: CustomLoginData = loginService.getUser();
    if (!userData) {
      return;
    }
    const headers = loginService.getAuthorizationHeaderForToken(userData.token);
    headers["Content-Type"] = "application/json";
    headers["Accept"] = "application/json";

    try {
      const response = await fetch(`${BASE_URL}/api/Auth/GetProfileData`, {
        method: `GET`,
        headers: headers
      });
      checkResponseStatus(response);
      let profileData = await response.json();
      loginService.updateProfileData(profileData);
      // return userData;
    } catch (e) {
      Sentry.captureMessage(`Problem in updateUserProfile': ${e}`, "error");
      throw e;
    }
  };

  // ---------------------------------------------------------------------------
  // Hooks

  const useIsAValidLoginToken = (setIsLoggedIn) => {
    useEffect(() => {
      const alreadyAuthenticated = loginService.isAuthenticated();
      if (!alreadyAuthenticated) {
        setIsLoggedIn(false);
        return;
      }
      const currentUser = loginService.getUser();
      const validTokenLogin = async () => {
        const validToken = await isAValidLoginToken(
          currentUser.walletAddress,
          currentUser.token
        );
        setIsLoggedIn(validToken);
      };
      validTokenLogin().catch(genericErrorCallback);
    }, []);
  };

  // ---------------------------------------------------------------------------
  // api

  return {
    getNonceForOnboardedUser,
    walletAuthentication,
    updateUserSession,
    useIsAValidLoginToken,
    updateUserProfile,
    refreshProfileData,
    getUserWalletNonce
  };
};

export default altrLoginAPI;
