import Button from "~components/Common/Button";
import useApp from "~hooks/useApp";

const OfferAction = () => {
  const { setActiveOverlay } = useApp();

  const handleMakeAnOffer = () => {
    setActiveOverlay(`NFTOverlayOfferWhole`);
  };

  return (
    <section className="offer">
      <h3>Make an Offer</h3>
      <p>
        Place your offer now and watch for the seller's acceptance.
        <br />
        Remember, the seller can fill the order anytime, so make sure your wallet is funded.
      </p>
      <Button variant="primaryTall" fluid onClick={handleMakeAnOffer}>
        Make an Offer
      </Button>
    </section>
  );
};

export default OfferAction;
