import {
  ApprovalOverrides,
  Fee,
  FillOrderOverrides,
  NftOrderV4,
  NftSwapV4,
  SignedNftOrderV4,
  SwappableAssetV4,
  UserFacingERC1155AssetDataSerializedV4,
  UserFacingERC20AssetDataSerializedV4,
  UserFacingERC721AssetDataSerializedV4
} from "@traderxyz/nft-swap-sdk";
import { PostOrderResponsePayload, SearchOrdersParams } from "@traderxyz/nft-swap-sdk/dist/sdk/v4/orderbook";
import {
  ProductDetailVMOfBaseProductVM,
  MakerOrderOfTraderErc1155Order,
  MakerOrderOfTraderErc721Order,
  MakerOrderOfTraderGenericOrder
} from "~dApp/models/ApiModel";
import { orderbookRootUrl, TARGET_CHAIN } from "~utils/helpers";
import AltrApiService from "~dApp/AltrApiService";
import { erc20Abi, parseUnits, PublicClient, WalletClient } from "viem";
import externalIntegrationsImpl from "./implementations/externalIntegrationsImpl";
import { ApprovalStatus } from "@traderxyz/nft-swap-sdk/dist/sdk/common/types";
import { NFTDataVM } from "./models/ApiViewModel";

type MakerOrder = MakerOrderOfTraderErc1155Order | MakerOrderOfTraderErc721Order | MakerOrderOfTraderGenericOrder;

export default class TraderService {
  private altrApiService: AltrApiService;
  private publicClient: PublicClient;
  private address: `0x${string}`;
  public nftSwap: NftSwapV4;

  public get makerFeeData() {
    return `0x01`;
  }

  public get takerFeeData() {
    return `0x02`;
  }

  constructor(publicClient: PublicClient, walletClient: WalletClient) {
    this.nftSwap = new NftSwapV4(publicClient, walletClient, TARGET_CHAIN.id, { orderbookRootUrl });
    this.altrApiService = new AltrApiService();
    this.publicClient = publicClient;
    this.address = walletClient?.account?.address;
  }

  async getTokenDecimals(nftData: NFTDataVM) {
    const tokenAddress = Object.keys(nftData.nftCollectionInfo.tokens)[0];
    return this.publicClient.readContract({ address: tokenAddress as `0x${string}`, functionName: `decimals`, args: [], abi: erc20Abi });
  }

  async buildAssetFromEnrichedProduct(enrichedProduct: ProductDetailVMOfBaseProductVM, totalPrice: bigint) {
    const { nftData } = enrichedProduct;

    const decimals = await this.getTokenDecimals(nftData);
    const tokenAddress = Object.keys(nftData.nftCollectionInfo.tokens)[0];

    return this.buildAsset(tokenAddress, `ERC20`, `0`, parseUnits(totalPrice.toString(), decimals).toString());
  }

  buildAsset(tokenAddress: string, type: `ERC20` | `ERC721` | `ERC1155`, tokenId?: string, amount?: string): SwappableAssetV4 {
    let asset: SwappableAssetV4;
    switch (type) {
      case `ERC20`:
        asset = { tokenAddress, type, amount };
        break;
      case `ERC721`:
        asset = { tokenAddress, type, tokenId };
        break;
      case `ERC1155`:
        asset = { tokenAddress, type, tokenId, amount };
        break;
    }

    return asset;
  }

  async get0xTokenAllowance(tokenAddress: `0x${string}`): Promise<bigint> {
    return this.publicClient.readContract({
      address: tokenAddress,
      abi: erc20Abi,
      args: [this.address, this.nftSwap.exchangeProxyContractAddress],
      functionName: `allowance`
    });
  }

  async getFees(productIdentifier: string, amount: bigint): Promise<{ makerFee: bigint; takerFee: bigint; fees: Fee[] }> {
    const nftFeeData = await externalIntegrationsImpl.api.getNftFeeData(productIdentifier);

    const makerFee = (BigInt(amount) * BigInt(nftFeeData.makerFee)) / 100n;
    const takerFee = (BigInt(amount) * BigInt(nftFeeData.takerFee)) / 100n;

    const fees = [
      { amount: makerFee, recipient: nftFeeData.feeManagerAddress, feeData: this.makerFeeData },
      { amount: takerFee, recipient: nftFeeData.feeManagerAddress, feeData: this.takerFeeData }
    ];
    return { makerFee, takerFee, fees };
  }

  async approveSwappableAsset(token: SwappableAssetV4): Promise<string | null> {
    let approvalAmount: string;
    if (token.type === `ERC20`) {
      approvalAmount = token.amount;
    }

    const approvalStatus = await this.loadApprovalStatus(token, this.address);
    if (!approvalStatus.contractApproved || !approvalStatus?.tokenIdApproved) {
      const hash = await this.nftSwap.approveTokenOrNftByAsset(token, this.address, {}, { approvalAmount });
      return this.waitTransactionConfirmations(hash);
    }
    return null;
  }

  async approveTokenOrder(order: PostOrderResponsePayload, fractionsAmountOverride?: string): Promise<string> {
    const singleOrder = order.order;

    let asset: SwappableAssetV4;
    if (order.sellOrBuyNft === `sell`) {
      const priceWithFees = (BigInt(singleOrder.erc20TokenAmount) + singleOrder.fees.reduce((acc, fee) => acc + BigInt(fee.amount), 0n)).toString();
      asset = {
        tokenAddress: singleOrder.erc20Token,
        amount: fractionsAmountOverride
          ? ((BigInt(priceWithFees) * BigInt(fractionsAmountOverride)) / BigInt(order.nftTokenAmount)).toString()
          : singleOrder.erc20TokenAmount,
        type: `ERC20`
      };
      const hash = await this.nftSwap.approveTokenOrNftByAsset(asset, this.address, {}, { approvalAmount: priceWithFees });
      return this.waitTransactionConfirmations(hash);
    } else if (order.nftType === `ERC721`) {
      asset = {
        tokenAddress: order.nftToken,
        tokenId: order.nftTokenId,
        type: `ERC721`
      };
    } else {
      asset = {
        tokenAddress: order.nftToken,
        tokenId: order.nftTokenId,
        amount: fractionsAmountOverride || order.nftTokenAmount,
        type: `ERC1155`
      };
    }

    return this.approveSwappableAsset(asset);
  }

  async createSellOrder(
    product: ProductDetailVMOfBaseProductVM,
    askTokenAddress: string,
    askAmount: bigint,
    type: `ERC721` | `ERC1155`,
    fractionsAmount?: string,
    expiry?: string
  ): Promise<PostOrderResponsePayload> {
    let nftToSell: SwappableAssetV4;
    if (type === `ERC721`) {
      nftToSell = {
        tokenAddress: product.nftData.nftCollectionInfo.collectionAddress,
        tokenId: product.nftData.tokenId.toString(),
        type
      } as UserFacingERC721AssetDataSerializedV4;
    } else {
      nftToSell = {
        tokenAddress: product?.nftData?.nftCollectionInfo?.fractionSaleAddresses?.fractionsContractAddress,
        tokenId: product?.nftData?.saleId.toString(),
        type,
        amount: fractionsAmount
      } as UserFacingERC1155AssetDataSerializedV4;
    }

    const { makerFee, fees } = await this.getFees(product.product.identifier, askAmount);

    const erc20ToAsk: SwappableAssetV4 = {
      tokenAddress: askTokenAddress,
      amount: (BigInt(askAmount) - BigInt(makerFee)).toString(),
      type: `ERC20`
    } as UserFacingERC20AssetDataSerializedV4;

    const order = await this.nftSwap.buildOrder(nftToSell as any, erc20ToAsk, this.address, {
      fees,
      expiry: expiry || null
    });

    return this.signAndPostOrder(order as unknown as NftOrderV4);
  }

  async createBuyOrder(
    product: ProductDetailVMOfBaseProductVM,
    tokenAddress: string,
    amount: bigint,
    type: `ERC721` | `ERC1155`,
    fractionsAmount?: string,
    expiry?: string,
    taker?: string
  ): Promise<PostOrderResponsePayload> {
    let nftToBuy: SwappableAssetV4;
    if (type === `ERC721`) {
      nftToBuy = {
        tokenAddress: product.nftData.nftCollectionInfo.collectionAddress,
        tokenId: product.nftData.tokenId.toString(),
        type
      } as UserFacingERC721AssetDataSerializedV4;
    } else {
      nftToBuy = {
        tokenAddress: product.nftData.nftCollectionInfo.fractionSaleAddresses.fractionsContractAddress,
        tokenId: product.nftData.saleId.toString(),
        type,
        amount: fractionsAmount
      } as UserFacingERC1155AssetDataSerializedV4;
    }

    const { makerFee, fees } = await this.getFees(product.product.identifier, amount);

    const erc20ToSell: UserFacingERC20AssetDataSerializedV4 = {
      tokenAddress: tokenAddress,
      amount: (BigInt(amount) - BigInt(makerFee)).toString(),
      type: `ERC20`
    };

    const order = await this.nftSwap.buildOrder(erc20ToSell, nftToBuy as any, this.address, {
      fees,
      expiry: expiry || null,
      taker: taker || null
    });

    return this.signAndPostOrder(order as unknown as NftOrderV4);
  }

  async signAndPostOrder(order: NftOrderV4): Promise<PostOrderResponsePayload> {
    const signedOrder = await this.nftSwap.signOrder(order);
    const postedOrder = await this.nftSwap.postOrder(signedOrder, TARGET_CHAIN.id);
    return postedOrder;
  }

  async fulfillOrder(order: MakerOrder, overrides: Partial<FillOrderOverrides> = {}): Promise<string> {
    if (`nftToken` in order && !(`erc721TokenProperties` in order.order)) {
      const erc721Order = {
        ...order.order,
        erc721TokenProperties: [],
        erc721Token: order.nftToken,
        erc721TokenId: order.nftTokenId
      };
      order.order = erc721Order as any;
    }
    const hash = await this.nftSwap.fillSignedOrder(order?.order as unknown as SignedNftOrderV4, overrides);
    return this.waitTransactionConfirmations(hash);
  }

  async fulfillOrderByProduct(product: any, overrides?: Partial<FillOrderOverrides>): Promise<string> {
    const order = (await this.altrApiService.getOrderByProductIdentifier(product.product.identifier)) as MakerOrder;
    const transactionHash = await this.fulfillOrder(order, overrides);
    return transactionHash;
  }

  async cancelNftOrder(order: MakerOrder): Promise<`0x${string}`> {
    const hash = await this.nftSwap.cancelOrder(order.order.nonce, order.nftType as `ERC721` | `ERC1155`);
    return this.waitTransactionConfirmations(hash);
  }

  async cancelNftOrderByProduct(product: ProductDetailVMOfBaseProductVM): Promise<string> {
    const order = (await this.altrApiService.getOrderByProductIdentifier(product.product.identifier)) as MakerOrder;
    const hash = await this.cancelNftOrder(order);
    return hash;
  }

  async getOrdersByNonce(orderNonce: string) {
    const orders = await this.nftSwap.getOrders({ nonce: orderNonce });
    return orders;
  }

  async getOrdersByCollectionAddressAndTokenId(collectionAddress: string, tokenId: string, filters?: Partial<SearchOrdersParams> | undefined) {
    return this.nftSwap.getOrders({
      ...filters,
      nftToken: collectionAddress,
      nftTokenId: tokenId,
      nftType: filters?.nftType ?? `ERC1155`,
      chainId: 80002,
      sellOrBuyNft: filters?.sellOrBuyNft ?? `buy`,
      visibility: filters?.visibility ?? `public`,
      status: filters?.status ?? `filled`
    });
  }

  async loadApprovalStatus(asset: SwappableAssetV4, address: `0x${string}`, approvalOverrides?: ApprovalOverrides): Promise<ApprovalStatus> {
    return this.nftSwap.loadApprovalStatus(asset, address, approvalOverrides);
  }

  async waitTransactionConfirmations(hash: `0x${string}`) {
    const res = await this.publicClient.waitForTransactionReceipt({ hash });
    if (res.status === `reverted`) throw new Error(`Altr: Transaction reverted`);
    return hash;
  }
}
