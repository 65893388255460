import { navigate } from "gatsby";
import { TARGET_CHAIN } from "./helpers";

export const parseCustomError = (error: Error): string => {
  if (!error?.message) return "Error";
  const words = error.message.split(" ");
  const customError = words.find((word) => word.includes("(") && word.includes(")"));

  return (
    customError
      ?.split(/(?=[A-Z])/)
      ?.join(" ")
      ?.split("(")?.[0] || parseError(error)
  );
};

// Parses error messages to extract specific terms and conditions.
export const parseError = (error: Error): string => {
  if (!error?.message) return "Error";

  const errorMap = {
    Altr: "Altr",
    ERC20: "ERC20",
    ERC721: "ERC721",
    ERC1155: "ERC1155",
    network: `Invalid chain, please switch to ${TARGET_CHAIN.name}`
  };

  let searchError = Object.keys(errorMap).find((key) => error.message.includes(key));
  let detailedError = errorMap[searchError] || "Error";

  if (detailedError && detailedError !== "Error" && searchError !== "network") {
    const words = error.message.split(" ");
    const index = words.findIndex((word) => word.includes(searchError));

    if (index !== -1) {
      let errorMessage = words
        .slice(index + 1)
        .join(" ")
        .split(/["\n]/)[0];
      detailedError = errorMessage.charAt(0).toUpperCase() + errorMessage.slice(1);
    }
  }

  return detailedError;
};

// Handles errors by setting an overlay with appropriate messages and actions.
export const handleError = async (error: Error, setErrorOverlay: (data: any) => void, balance: bigint) => {
  if (error.message.includes("User rejected the request") || error.message.includes("User denied transaction signature")) {
    return;
  }
  const parsedError = parseError(error).toLowerCase();

  switch (parsedError) {
    case `transfer amount exceeds balance`:
      setErrorOverlay({
        icon: "cross",
        heading: "USDt balance too low.",
        action: () => navigate("/profile/balances"),
        actionText: "Top up USDt"
      });
      return "USDt balance too low, please top up and try again.";
    case `error`:
      setErrorOverlay({
        icon: "cross",
        heading: balance <= 0n ? "MATIC balance too low." : parsedError,
        action: balance <= 0n ? () => navigate("/profile/balances") : undefined,
        actionText: balance <= 0n ? "Top up MATIC" : undefined
      });
      return balance <= 0n ? "MATIC balance too low, please top up and try again." : parsedError;
    default:
      setErrorOverlay({
        icon: "cross",
        heading: parsedError
      });
      break;
  }
};
