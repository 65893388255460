import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Grid, SVG } from "~components";
import { breakpoint } from "~utils/css";
import NFTOwnersList from "./NFTOwnersList";
import NFTSellOrdersList from "./NFTSellOrdersList";
import NFTBuyOrdersList from "./NFTBuyOrdersList";
import NFTTransactionsList from "./NFTTransactionsList";

/** ============================================================================
 * @css
 */
const DROPDOWN_BUTTON_HEIGHT = `54px`;

const Container = styled.section`
  width: 100%;
  position: relative;
  display: block;
  padding: 2.5rem 0 3.75rem;

  .nftActivityControls {
    &Count {
      min-width: 40px;
      height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 2px;
      margin-left: 0.5rem;
      border: 1px solid var(--color-khaki-100);
      border-radius: 56px;
    }

    &Button {
      &Flex {
        color: var(--color-khaki-100);
        display: flex;
        align-items: center;
      }

      &Active {
        color: black;

        .nftActivityControlsCount {
          border: 1px solid black;
        }
      }
    }
  }
`;

const Toolbar = styled.header`
  grid-column: 1 / -1;
  position: relative;
  overflow: hidden;
  display: none;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 4rem;
  padding: 1.75rem;
  background: var(--color-beige);
  border-radius: 12px;
  ${breakpoint(`large-tablet`)} {
    display: flex;
  }
`;

const ToolbarButtons = styled.div`
  position: relative;
  overflow: hidden;
  display: flex;
  gap: 0 2rem;
`;

const Dropdown = styled.nav`
  transition: 0.3s var(--cubic-easing) height;
  height: ${({ expanded }) => `${expanded ? `250px` : DROPDOWN_BUTTON_HEIGHT}`};
  margin: 0 1rem 1rem;
  position: relative;
  pointer-events: auto;
  display: block;
  overflow: hidden;
  background: var(--color-beige);
  color: var(--color-black);
  border-radius: 8px;

  ${breakpoint(`small-tablet`)} {
    margin: 0 2rem 1rem;
  }
  ${breakpoint(`large-tablet`)} {
    display: none;
  }
`;

const DropdownExpander = styled.button`
  width: 100%;
  height: ${DROPDOWN_BUTTON_HEIGHT};
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 1rem;

  svg {
    transform: scaleY(${({ expanded }) => `${expanded ? `-` : ``}`}1);

    height: 7px;
  }

  .accountSwitcherBannerLeft {
    display: flex;
    align-items: center;
  }
`;

const DropdownList = styled.ul`
  width: 100%;
  height: 244px;
  position: relative;
  padding: 0 1rem;
  display: block;
  background: var(--color-mono-70);
  color: var(--color-white);
`;

const DropdownButton = styled.li`
  width: 100%;
  height: 48px;
  position: relative;
  display: flex;
  align-items: center;
  background: var(--color-mono-70);

  button {
    width: 100%;
    height: 100%;
    text-align: left;
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */

const NFTActivityControls = ({
  nft: { enrichedProduct, nftStatus },
  page = `product` // `product` | `collection`
}) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const [activeTab, setActiveTab] = useState(0);
  const [expanded, setExpanded] = useState(false);
  const [tabsLength, setTabsLength] = useState({ [`Transaction History`]: null });
  const setTabsLengthCallback = (id, length) => {
    setTabsLength((prev) => ({ ...prev, [id]: length }));
  };

  const tabs = [
    {
      id: nftStatus?.is([`SoldOut`]) ? `Current Owner` : `Owner’s Board`,
      length() {
        return tabsLength[tabs[0].id];
      }
    },
    {
      id: `Sell Orders`,
      length() {
        return tabsLength[tabs[1].id];
      }
    },
    {
      id: `Buy Orders`,
      length() {
        return tabsLength[tabs[2].id];
      }
    },
    page === `product` && {
      id: `Transaction History`,
      length() {
        return tabsLength[tabs[3].id];
      }
    }
  ];
  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    setExpanded(false);
  }, [activeTab]);

  // ---------------------------------------------------------------------------
  // render
  const activeTabTitle = tabs[activeTab].id;

  if (!nftStatus?.is([`SoldOut`, `OnFractionsSaleSuccess`])) {
    return null;
  }

  return (
    <Container>
      <Grid>
        <Toolbar>
          <ToolbarButtons>
            {tabs.map((tab, tabIndex) => {
              const active = tabIndex === activeTab;

              return (
                <button type="button" onClick={() => setActiveTab(tabIndex)} key={tabIndex}>
                  <div className={`nftActivityControlsButtonFlex ${active ? `nftActivityControlsButtonActive` : ``}`}>
                    <span className="b1">{tab.id}</span>
                    <div className="nftActivityControlsCount caption">{tabsLength?.[tab.id] ? tab.length() : 0}</div>
                  </div>
                </button>
              );
            })}
          </ToolbarButtons>
        </Toolbar>
      </Grid>

      <Dropdown expanded={expanded}>
        <DropdownExpander type="button" className="accountSwitcherDropdownExpander" expanded={expanded} onClick={() => setExpanded(!expanded)}>
          <div className="accountSwitcherBannerLeft">
            <h4 className="b1">{activeTabTitle}</h4>
            <div className="nftActivityControlsCount caption">{tabsLength?.[tabs[activeTab].id] ? tabs[activeTab]?.length() : 0}</div>
          </div>

          <SVG svg="chevronDown" />
        </DropdownExpander>

        <DropdownList>
          {tabs.map((tab, tabIndex) => (
            <DropdownButton key={tabIndex} active={activeTab === tabIndex}>
              <button type="button" onClick={() => setActiveTab(tabIndex)} className="b1">
                {tab.id}
              </button>
            </DropdownButton>
          ))}
        </DropdownList>
      </Dropdown>
      <NFTOwnersList
        id={nftStatus?.is([`SoldOut`]) ? `Current Owner` : `Owner’s Board`}
        activeId={activeTabTitle}
        enrichedProduct={enrichedProduct}
        nftStatus={nftStatus}
        page={page}
        setTabsLength={setTabsLengthCallback}
      />
      <NFTSellOrdersList
        id="Sell Orders"
        activeId={activeTabTitle}
        enrichedProduct={enrichedProduct}
        nftStatus={nftStatus}
        page={page}
        setTabsLength={setTabsLengthCallback}
      />
      <NFTBuyOrdersList
        id="Buy Orders"
        activeId={activeTabTitle}
        enrichedProduct={enrichedProduct}
        nftStatus={nftStatus}
        page={page}
        setTabsLength={setTabsLengthCallback}
      />
      <NFTTransactionsList
        id="Transaction History"
        activeId={activeTabTitle}
        enrichedProduct={enrichedProduct}
        nftStatus={nftStatus}
        page={page}
        setTabsLength={setTabsLengthCallback}
      />
    </Container>
  );
};

export default NFTActivityControls;
