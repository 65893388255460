import { createContext } from "react";
import externalIntegrationsImpl from "~dApp/implementations/externalIntegrationsImpl";
import IExternalIntegrations from "~dApp/models/IExternalIntegrations";
import externalIntegrationsMock from "~dApp/implementations/externalIntegrationsMock";

const externalIntegrations = externalIntegrationsImpl;
// const externalIntegrations = externalIntegrationsMock;

const ExternalIntegrationsContext =
  createContext<IExternalIntegrations>(externalIntegrations);

export default ExternalIntegrationsContext;
