import React, { useEffect, useState } from "react";
import { useApp, usePurchaseData } from "~hooks";
import { NFTIconNote, NFTCheckout, NFTOverlay, NFTNumberEntry, NFTTimeSelect } from "~components";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { blockchainHooks } from "~hooks/blockchainHooks";
import { handleError } from "~utils/error";
import { usePublicClient, useWalletClient } from "wagmi";
import { useParseTraderOrder } from "~hooks/useParseTraderOrder";

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlayOfferWhole = ({ nft, activeOwner, completionCallback, sellOrder }) => {
  const expires = { "24 hours": 86400, "7 days": 604800, "15 days": 1296000 };
  // ---------------------------------------------------------------------------
  // imports / hooks

  const {
    setOverlayCompletionData,
    setActiveOverlay,
    activeOverlay,
    userData: {
      balances: { maticBalance, usdtBalance }
    }
  } = useApp();
  const { useManage0xTokenAllowance } = blockchainHooks();
  const { enrichedProduct } = nft;

  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();

  const { trader } = useExternalIntegrations();

  // ---------------------------------------------------------------------------
  // context / ref / state

  const { data: purchaseData, approved, setApproved, update, onChange, reset, error } = usePurchaseData();
  const [executing, setExecuting] = useState(false);
  const [isMoreThanSellOrder, setIsMoreThanSellOrder] = useState(false);

  // ---------------------------------------------------------------------------
  // methods

  const executeApproval = async () => {
    if (!enrichedProduct?.nftData || executing) {
      return;
    }
    setExecuting(true);
    const traderSdk = trader(publicClient, walletClient);

    try {
      const asset = await traderSdk.buildAssetFromEnrichedProduct(enrichedProduct, purchaseData?.price || 0);
      const { takerFee } = await traderSdk.getFees(enrichedProduct.product.identifier, BigInt(asset.amount));
      asset.amount = (BigInt(asset.amount) + takerFee).toString();
      await traderSdk.approveSwappableAsset(asset);
    } catch (e) {
      handleError(e, setOverlayCompletionData, maticBalance?.value);
      console.error(e);
    }

    setExecuting(false);
  };

  const makeOffer = async () => {
    if (!enrichedProduct?.product || !enrichedProduct?.nftData || executing) {
      return () => {};
    }

    setExecuting(true);

    const traderSdk = trader(publicClient, walletClient);

    try {
      const asset = await traderSdk.buildAssetFromEnrichedProduct(enrichedProduct, purchaseData?.price || 0);
      await traderSdk.createOrder(
        enrichedProduct,
        asset.tokenAddress,
        asset.amount,
        `buy`,
        `ERC721`,
        `1`,
        Math.floor(Date.now() / 1000) + expires[purchaseData?.expiry],
        activeOwner?.address
      );

      reset();
      completionCallback?.();
      setActiveOverlay(null);
      setOverlayCompletionData({
        heading: `Your offer has been sent to the owner`,
        body: `Be sure to always have the offered amount to allow the transfer when the deal is accepted.`
      });
    } catch (e) {
      console.error(e);
      handleError(e, setOverlayCompletionData, maticBalance?.value);
    }

    setExecuting(false);

    return null;
  };

  // ---------------------------------------------------------------------------
  // lifecycle
  const { refetch } = useManage0xTokenAllowance(
    enrichedProduct,
    parseFloat(purchaseData?.price || 0),
    setApproved,
    true,
    false,
    activeOverlay === `NFTOverlayOfferWhole`
  );

  const {
    price: { displayPrice }
  } = useParseTraderOrder(sellOrder.order);

  useEffect(() => {
    setIsMoreThanSellOrder(parseFloat(purchaseData?.price) > parseFloat(displayPrice.replace(/,/g, ``)));
  }, [purchaseData?.price, displayPrice]);

  // ---------------------------------------------------------------------------
  // render

  if (!nft?.enrichedProduct) {
    return null;
  }

  return (
    <NFTOverlay id="NFTOverlayOfferWhole" heading="Make an offer" nft={nft} sidebarMode="offer">
      <NFTNumberEntry
        className="nftOverlayGroup"
        name="price"
        onChange={onChange}
        heading="1. How much would you like to offer?"
        placeholder="Enter price"
        min={0}
        max={(parseFloat(usdtBalance?.formatted || 0) / (parseFloat(enrichedProduct?.nftData?.makerFee.split(`%`)[0]) / 100 + 1)).toFixed(
          usdtBalance?.decimals
        )}
      />

      <NFTTimeSelect
        className="nftOverlayGroup"
        onSelect={(value) => {
          update(`expiry`, value);
        }}
        heading="2. Choose how long this offer will be valid for:"
      />

      <NFTCheckout
        className="nftOverlayGroup"
        heading="Your Offer"
        subheading={`Price includes ${enrichedProduct?.nftData?.makerFee} platform fee.`}
        subheadingVisible={parseFloat(enrichedProduct?.nftData?.makerFee.split(`%`)[0]) > 0}
        fee={enrichedProduct?.nftData?.makerFee}
        finalButtonText="Make an Offer"
        nft={nft}
        data={purchaseData}
        execute={makeOffer}
        executeApproval={executeApproval}
        type="whole"
        approved={approved}
        onReset={reset}
        refetch={refetch}
        prepareError={error}
        valid={typeof purchaseData?.price !== `undefined` && parseInt(purchaseData?.price) > 0 && purchaseData?.expiry !== null}
        approveLoading={executing && !approved}
        actionLoading={executing && approved}
      />

      <NFTIconNote
        background="rgba(255, 255, 255, 0.4)"
        fontClass="caption"
        svg="alert"
        text="Once you approve the offer, the smart contract can withdraw your USDt in any moment. Ensure sufficient funds to allow the owner's acceptance of the deal."
      />

      {isMoreThanSellOrder && <NFTIconNote background="rgba(255, 255, 255, 0.4)" fontClass="caption" svg="alert" text="Cheaper option available on sale." />}
    </NFTOverlay>
  );
};

export default NFTOverlayOfferWhole;
