import React, { useMemo } from "react";
import cn from "classnames";
import { Link, SVG, Button, ProfileItemActionsButtons } from "~components";
import { useApp } from "~hooks";
import { truncateAddress, BLOCK_EXPLORER_ADDRESS, BLOCK_EXPLORER_TOKEN, thousandCommas } from "~utils/helpers.js";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { UserPurchaseabilityStatus } from "~dApp/models/profile/UserPurchaseabilityStatus";

/** ============================================================================
 * @component
 */
const OwnershipStatus = ({ className, item, nft, certificationRequestDetails, readOnly = false }) => {
  const { api } = useExternalIntegrations();
  const { setOverlayCompletionData, setOverlayProcessingData, kycData } = useApp();

  const isCertificate = item?.raw.metadata?.certificatetype?.toLowerCase();
  const isLightCertificate = item?.raw.metadata?.certificatetype?.toLowerCase() === `light`;
  const isKycd = [UserPurchaseabilityStatus.Whitelisted, UserPurchaseabilityStatus.Verified].includes(kycData.kycStatus);

  const ownership = useMemo(() => {
    if (!item) return ``;

    if (item.tokenType === `ERC721`) return `Whole item`;

    return `${item.balance}/${item.raw.metadata.amount}`;
  }, [item]);

  const certificateLabel = useMemo(() => {
    if (isLightCertificate) return `Light certificate`;

    return `Full certificate`;
  }, [isCertificate, isLightCertificate]);

  const hasRequestedUpgrade = !!certificationRequestDetails && certificationRequestDetails.lastStatus?.status === 25;

  return (
    <section className={cn(`ownershipStatus`, className)}>
      <h2>{item.name}</h2>
      <ul className="info">
        <li className="numeric">
          <dl>
            <dt>Collection</dt>
            <dd>
              <Link to={`${BLOCK_EXPLORER_ADDRESS}${item?.contract?.address}`}>
                <span>{truncateAddress(item?.contract?.address)}</span>
                <SVG svg="chain" className="iconChain" />
              </Link>
            </dd>
          </dl>
        </li>
        <li className="numeric id">
          <dl>
            <dt>Nft ID</dt>
            <dd>
              <Link className="sidebarLink" to={`${BLOCK_EXPLORER_TOKEN}${item?.contract?.address}?a=${item?.tokenId}`}>
                <span>{item?.tokenId}</span>
                <SVG svg="chain" className="iconChain" />
              </Link>
            </dd>
          </dl>
        </li>
        {!readOnly && (
          <>
            {!isCertificate && (
              <li>
                <dl>
                  <dt>First sale price</dt>
                  <dd>
                    {item.tokenType === `ERC721`
                      ? thousandCommas(nft?.enrichedProduct?.product?.price?.usdAmount || `-`) || `-`
                      : nft?.enrichedProduct?.product?.fragmentsPrice || `-`}{" "}
                    USDt
                  </dd>
                </dl>
              </li>
            )}
            {!isCertificate && (
              <li>
                <dl>
                  <dt>Ownership status</dt>
                  <dd>{ownership}</dd>
                </dl>
              </li>
            )}
            <li>
              <dl>
                <dt>Digitise status</dt>
                <dd>
                  <span>{certificateLabel}</span>
                  {!readOnly && !hasRequestedUpgrade && isLightCertificate && isKycd && (
                    <Button
                      variant="secondary"
                      iconRight="arrowRight"
                      className="upgrade"
                      onClick={async () => {
                        setOverlayProcessingData({
                          icon: `load`,
                          text: `Processing. Please do not close this page.`
                        });
                        try {
                          await api.createFullCertificationRequest(item.raw.metadata.nonce);
                          setOverlayProcessingData(null);
                          setOverlayCompletionData({
                            icon: `check`,
                            heading: `Full certificate requested`
                          });
                        } catch (e) {
                          setOverlayProcessingData(null);
                          setOverlayCompletionData({
                            icon: `cross`,
                            heading: `Error. Please try again.`
                          });
                        }
                      }}
                      fluid
                    >
                      <span className="text">Upgrade to full</span>
                    </Button>
                  )}
                </dd>
              </dl>
            </li>
            {/* FIXME: find a way to reinstate displaying the vault of digitised products */}
            {/* {!isLightCertificate && (
              <li>
                <dl>
                  <dt>Vault</dt>
                  <dd>Vault Name</dd>
                </dl>
              </li>
            )} */}
          </>
        )}
      </ul>
      {!isLightCertificate && <ProfileItemActionsButtons item={item} nft={nft} readOnly={readOnly} />}
    </section>
  );
};

export default OwnershipStatus;
