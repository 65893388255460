import React from "react";
import cn from "classnames";
import { formatStablecoin, thousandCommas, truncateAddress, walletAddressAreEqual } from "~utils/helpers";
import { useMarketplaceItemContext } from "../MarketplaceItemContext";
import useApp from "~hooks/useApp";
import { useParseTraderOrder } from "~hooks/useParseTraderOrder";

const MarketplaceItemOrders = ({ sellOrder }) => {
  const { userData } = useApp();
  const { offers, selectedOffer, setSelectedOffer } = useMarketplaceItemContext();
  const {
    price: { displayPriceWithMakerFee },
    eta,
    from,
    calculatePercentageDifference,
    getPriceWithMakerFee,
    getPriceWithTakerFee,
    makeDisplayPrice,
    getEtaFromExpiry
  } = useParseTraderOrder(sellOrder.order);

  const isWhole = !!sellOrder.order.erc721Token;
  const isOwner = walletAddressAreEqual(userData?.address, sellOrder.order.maker);

  return (
    <section className="offers tabContent">
      {!isOwner && (
        <>
          <h3>Owner's Sell Order</h3>
          <table className="baseOffer">
            <thead>
              <tr>
                <th>PRICE</th>
                {!isWhole && <th>QUANTITY</th>}
                <th className="hidden" aria-hidden>
                  FLOOR DIFFERENCE
                </th>
                <th>EXPIRATION</th>
                <th>FROM</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>{displayPriceWithMakerFee} USDt</td>
                {!isWhole && <td>{sellOrder.order.erc1155Amount}</td>}
                <td></td>
                <td>{eta.toUpperCase()}</td>
                <td>{from}</td>
              </tr>
            </tbody>
          </table>
        </>
      )}
      <h3>Buy Offers</h3>
      <div className="tableWrapper hiddenScroll">
        {(!offers || offers.length === 0) && <span>No offers have been made yet, be the first to make one!</span>}
        {offers && offers.length > 0 && (
          <table>
            <thead>
              <tr>
                <th>PRICE</th>
                {!isWhole && <th>QUANTITY</th>}
                <th>FLOOR DIFFERENCE</th>
                <th>EXPIRATION</th>
                <th>FROM</th>
              </tr>
            </thead>
            <tbody>
              {offers.map((offer, i) => {
                if (!offer?.order) return;
                const price = isOwner ? getPriceWithMakerFee(offer.order) : getPriceWithTakerFee(offer.order);
                const displayPrice = makeDisplayPrice(BigInt(price));
                const eta = getEtaFromExpiry(offer.order).toUpperCase();

                const from = walletAddressAreEqual(userData?.address, offer.order.maker) ? `YOU` : truncateAddress(offer.order.maker, 4, 6);

                const floorDifference = calculatePercentageDifference(offer.order, sellOrder.order);
                return (
                  <tr key={i} className={cn(i === selectedOffer.id && `selected`)} onClick={() => setSelectedOffer(i)}>
                    <td>{displayPrice} USDt</td>
                    {!isWhole && <td>{offer.order.erc1155Amount}</td>}
                    <td>{floorDifference.startsWith("-") ? `${floorDifference.slice(1)} BELOW` : `${floorDifference} ABOVE`}</td>
                    <td>{eta}</td>
                    <td>{from}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </section>
  );
};

export default MarketplaceItemOrders;
