import NFTOverlayCompletion from "~components/Marketplace/NFTOverlays/NFTOverlayCompletion";
import MarketplaceItemActions from "./MarketplaceItemActions";
import MarketplaceItemActionsOverlays from "./MarketplaceItemActions/MarketplaceItemActionsOverlays";
import MarketplaceItemProvider from "./MarketplaceItemContext";
import MarketplaceItemInfo from "./MarketplaceItemInfo";
import Balances from "~components/Common/Balances";
import Button from "~components/Common/Button";

const MarketplaceItem = ({ product, sellOrder, isOwner }) => (
  <MarketplaceItemProvider nftData={product.nftData}>
    <div id="marketplaceItem" className="bgWrapper">
      <section className="container wide light">
        <div className="header">
          <Button iconLeft="arrowLeft" to="/marketplace">
            Back
          </Button>
          <Balances />
        </div>
        <div className="body">
          <h2 className="visually-hidden">{product.product.productName}</h2>
          <MarketplaceItemInfo product={product} sellOrder={sellOrder} />
          <MarketplaceItemActions product={product} sellOrder={sellOrder} isOwner={isOwner} />
        </div>
      </section>
      <MarketplaceItemActionsOverlays nft={{ enrichedProduct: product }} sellOrder={sellOrder} />
      <NFTOverlayCompletion />
    </div>
  </MarketplaceItemProvider>
);

export default MarketplaceItem;
