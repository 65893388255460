import { Fragment } from "react";
import { BLOCK_EXPLORER_ADDRESS, BLOCK_EXPLORER_NFT, BLOCK_EXPLORER_TOKEN, handleImageClick, thousandCommas, truncateAddress } from "~utils/helpers";
import { Button, PriceWidget, Image } from "~components";

const MarketplaceItemDetails = ({ product, onValuationClick, isWhole, owner }) => (
  <section className="itemDetails">
    <div className="images">
      <figure>
        <a href={product.product.nftImage.publicUrl} target="_blank" rel="noreferrer" onClick={(e) => handleImageClick(e, product.product.nftImage.publicUrl)}>
          <span className="visually-hidden">{`${product.product.model} image`}</span>
          <Image alt={product.product.model} image={product.product.nftImage.publicUrl} fill />
        </a>
      </figure>
      {product.product.images.slice(0, 2).map((image, i) => (
        <figure key={`${image.publicUrl}-${i}`}>
          <a href={image.publicUrl} target="_blank" rel="noreferrer" onClick={(e) => handleImageClick(e, image.publicUrl)}>
            <span className="visually-hidden">{`${product.product.productName} image`}</span>
            <Image alt={product.product.productName} image={image.publicUrl} fill />
          </a>
        </figure>
      ))}
    </div>
    <div className="parameters tabContent">
      <h3>{product.product.productName}</h3>
      <ul>
        <li className="secondary">
          <dl>
            <dt>Owned by</dt>
            <dd>
              {/** FIXME: this should be taken on-chian??? */}
              <a target="_blank" href={`${BLOCK_EXPLORER_ADDRESS}${owner}`}>
                <span>{truncateAddress(owner, 4, 6)}</span>
                <i className="icon chain">chain</i>
              </a>
            </dd>
          </dl>
        </li>
        <li className="secondary">
          <dl>
            <dt>NFT Type</dt>
            <dd>{isWhole ? `Whole` : `Fragments`}</dd>
          </dl>
        </li>
        <li className="secondary">
          <dl>
            <dt>Collection</dt>
            <dd>
              <a target="_blank" href={`${BLOCK_EXPLORER_TOKEN}${product.nftData.nftCollectionInfo.collectionAddress}`}>
                <span>{truncateAddress(product.nftData.nftCollectionInfo.collectionAddress, 4, 6)}</span>
                <i className="icon chain">chain</i>
              </a>
            </dd>
          </dl>
        </li>
        <li className="secondary">
          <dl>
            <dt>NFT Id</dt>
            <dd>
              <a target="_blank" href={`${BLOCK_EXPLORER_NFT}${product.nftData.nftCollectionInfo.collectionAddress}/${product.nftData.tokenId}`}>
                <span>{product.nftData.tokenId}</span>
                <i className="icon chain">chain</i>
              </a>
            </dd>
          </dl>
        </li>
        <li className="primary">
          <dl>
            <dt>WHERE IS THE ITEM?</dt>
            <dd>
              Dodici Motorsport
              <Button href="/oracles/dodici-motorsport/" variant="secondary" colorTheme="dark" fluid>
                Visit
                <i className="icon">arrow_right</i>
              </Button>
            </dd>
          </dl>
        </li>
        {product.product.productValueIndexDateAndPrices && product.product.productValueIndexDateAndPrices.length > 0 && (
          <li className="primary price">
            <dl>
              <dt>Evaluation</dt>
              <dd>
                <PriceWidget
                  displayPrice={thousandCommas(product.product.productValueIndexDateAndPrices.at(-1).price.toFixed(2))}
                  currencyVariant="secondary"
                  color="mono-90"
                />
                <Button onClick={onValuationClick} variant="secondary" className="metrics" colorTheme="dark" fluid>
                  Go to valuation metrics
                  <i className="icon">arrow_right</i>
                </Button>
              </dd>
            </dl>
          </li>
        )}
      </ul>
      <section className="description">
        <h4>Description</h4>
        <p dangerouslySetInnerHTML={{ __html: product.product.description.values["en-US"] }} />
        <dl className="attributes">
          {product.product.attributes.map((attribute) => (
            <Fragment key={attribute.label}>
              <dt>{attribute.label}</dt>
              <dd>{attribute.value}</dd>
            </Fragment>
          ))}
        </dl>
      </section>
    </div>
  </section>
);

export default MarketplaceItemDetails;
