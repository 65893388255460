import React from "react";
import useApp from "~hooks/useApp";
import cn from "classnames";
import { formatCryptoCurrency } from "~utils/helpers";

const Balances = ({ className }) => {
  const {
    userData: {
      balances: { maticBalance, usdtBalance, lcdBalance }
    }
  } = useApp();

  return (
    <div className={cn(className, `balances`)}>
      <span>USDt: {formatCryptoCurrency(usdtBalance?.formatted || 0)}</span>
      <span>MATIC: {formatCryptoCurrency(maticBalance?.formatted || 0)}</span>
      <span>LCD: {formatCryptoCurrency(lcdBalance?.formatted || 0)}</span>
    </div>
  );
};

export default Balances;
