exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-fragment-purchase-confirmation-[id]-jsx": () => import("./../../../src/pages/fragment-purchase-confirmation/[id].jsx" /* webpackChunkName: "component---src-pages-fragment-purchase-confirmation-[id]-jsx" */),
  "component---src-pages-fragment-purchase-confirmation-jsx": () => import("./../../../src/pages/fragment-purchase-confirmation/[...].jsx" /* webpackChunkName: "component---src-pages-fragment-purchase-confirmation-jsx" */),
  "component---src-pages-marketplace-[id]-index-jsx": () => import("./../../../src/pages/marketplace/[id]/index.jsx" /* webpackChunkName: "component---src-pages-marketplace-[id]-index-jsx" */),
  "component---src-pages-marketplace-index-jsx": () => import("./../../../src/pages/marketplace/index.jsx" /* webpackChunkName: "component---src-pages-marketplace-index-jsx" */),
  "component---src-pages-nfts-[id]-[token]-jsx": () => import("./../../../src/pages/nfts/[id]/[token].jsx" /* webpackChunkName: "component---src-pages-nfts-[id]-[token]-jsx" */),
  "component---src-pages-nfts-jsx": () => import("./../../../src/pages/nfts/[...].jsx" /* webpackChunkName: "component---src-pages-nfts-jsx" */),
  "component---src-pages-playground-jsx": () => import("./../../../src/pages/playground.jsx" /* webpackChunkName: "component---src-pages-playground-jsx" */),
  "component---src-pages-profile-balances-jsx": () => import("./../../../src/pages/profile/balances.jsx" /* webpackChunkName: "component---src-pages-profile-balances-jsx" */),
  "component---src-pages-profile-collection-[id]-token-id-jsx": () => import("./../../../src/pages/profile/collection/[id]/[tokenId].jsx" /* webpackChunkName: "component---src-pages-profile-collection-[id]-token-id-jsx" */),
  "component---src-pages-profile-collection-jsx": () => import("./../../../src/pages/profile/collection.jsx" /* webpackChunkName: "component---src-pages-profile-collection-jsx" */),
  "component---src-pages-profile-digitise-jsx": () => import("./../../../src/pages/profile/digitise.jsx" /* webpackChunkName: "component---src-pages-profile-digitise-jsx" */),
  "component---src-pages-profile-history-jsx": () => import("./../../../src/pages/profile/history.jsx" /* webpackChunkName: "component---src-pages-profile-history-jsx" */),
  "component---src-pages-profile-index-jsx": () => import("./../../../src/pages/profile/index.jsx" /* webpackChunkName: "component---src-pages-profile-index-jsx" */),
  "component---src-pages-profile-orders-jsx": () => import("./../../../src/pages/profile/orders.jsx" /* webpackChunkName: "component---src-pages-profile-orders-jsx" */),
  "component---src-pages-profile-settings-jsx": () => import("./../../../src/pages/profile/settings.jsx" /* webpackChunkName: "component---src-pages-profile-settings-jsx" */),
  "component---src-pages-purchase-confirmation-[id]-jsx": () => import("./../../../src/pages/purchase-confirmation/[id].jsx" /* webpackChunkName: "component---src-pages-purchase-confirmation-[id]-jsx" */),
  "component---src-pages-purchase-confirmation-jsx": () => import("./../../../src/pages/purchase-confirmation/[...].jsx" /* webpackChunkName: "component---src-pages-purchase-confirmation-jsx" */),
  "component---src-pages-raffle-participants-jsx": () => import("./../../../src/pages/raffle/participants/[...].jsx" /* webpackChunkName: "component---src-pages-raffle-participants-jsx" */),
  "component---src-pages-raffle-participants-raffle-id-jsx": () => import("./../../../src/pages/raffle/participants/[raffleId].jsx" /* webpackChunkName: "component---src-pages-raffle-participants-raffle-id-jsx" */),
  "component---src-pages-raffle-prize-claimed-[id]-jsx": () => import("./../../../src/pages/raffle-prize-claimed/[id].jsx" /* webpackChunkName: "component---src-pages-raffle-prize-claimed-[id]-jsx" */),
  "component---src-pages-raffle-prize-claimed-jsx": () => import("./../../../src/pages/raffle-prize-claimed/[...].jsx" /* webpackChunkName: "component---src-pages-raffle-prize-claimed-jsx" */),
  "component---src-pages-sandbox-index-jsx": () => import("./../../../src/pages/sandbox/index.jsx" /* webpackChunkName: "component---src-pages-sandbox-index-jsx" */),
  "component---src-pages-ticket-purchase-confirmation-[id]-jsx": () => import("./../../../src/pages/ticket-purchase-confirmation/[id].jsx" /* webpackChunkName: "component---src-pages-ticket-purchase-confirmation-[id]-jsx" */),
  "component---src-pages-ticket-purchase-confirmation-jsx": () => import("./../../../src/pages/ticket-purchase-confirmation/[...].jsx" /* webpackChunkName: "component---src-pages-ticket-purchase-confirmation-jsx" */),
  "component---src-templates-collection-jsx": () => import("./../../../src/templates/collection.jsx" /* webpackChunkName: "component---src-templates-collection-jsx" */),
  "component---src-templates-event-jsx": () => import("./../../../src/templates/event.jsx" /* webpackChunkName: "component---src-templates-event-jsx" */),
  "component---src-templates-matomo-opt-out-jsx": () => import("./../../../src/templates/matomoOptOut.jsx" /* webpackChunkName: "component---src-templates-matomo-opt-out-jsx" */),
  "component---src-templates-oracle-jsx": () => import("./../../../src/templates/oracle.jsx" /* webpackChunkName: "component---src-templates-oracle-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-product-jsx": () => import("./../../../src/templates/product.jsx" /* webpackChunkName: "component---src-templates-product-jsx" */),
  "component---src-templates-raffle-jsx": () => import("./../../../src/templates/raffle.jsx" /* webpackChunkName: "component---src-templates-raffle-jsx" */),
  "component---src-templates-terms-and-conditions-jsx": () => import("./../../../src/templates/termsAndConditions.jsx" /* webpackChunkName: "component---src-templates-terms-and-conditions-jsx" */)
}

