import { merge } from "lodash";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart, PointElement, Tooltip, LineElement, LinearScale, TimeScale, LogarithmicScale } from "chart.js";
import "chartjs-adapter-date-fns";
import { enUS } from "date-fns/locale";
import { formatDate, thousandCommas } from "~utils/helpers";

const chartAreaBorder = {
  id: "chartAreaBorder",
  afterDatasetDraw(chart, args, options) {
    const {
      ctx,
      chartArea: { top, left, width, height }
    } = chart;

    ctx.save();

    ctx.strokeStyle = options.lineColor;
    ctx.strokeRect(left, top, width - 1, height);
    ctx.restore();
  }
};

// Plugin to display image on top of point
const pointImagePlugin = {
  id: "pointImagePlugin",
  afterDatasetsDraw: (chart) => {
    const {
      ctx,
      data: { datasets }
    } = chart;

    ctx.save();

    datasets.forEach((dataset, datasetIndex) => {
      const meta = chart.getDatasetMeta(datasetIndex);
      if (!meta.hidden) {
        meta.data.forEach((element) => {
          const img = new Image();
          img.src = dataset.pointImage || "";
          const position = element.tooltipPosition(true);

          img.width = dataset.pointImageSizes?.width || 12;
          img.height = dataset.pointImageSizes?.height || 12;

          if (datasetIndex === 1 && element.x === chart.getDatasetMeta(0).data[0].x) return;
          ctx.drawImage(img, position.x - img.width / 2, position.y - (img.height + 10), img.width, img.height);
        });
      }
    });

    ctx.restore();
  }
};

const hoverLines = {
  id: "hoverLines",
  afterDatasetDraw: (chart, args, options) => {
    const {
      ctx,
      tooltip,
      chartArea: { bottom, left }
    } = chart;

    if (tooltip && tooltip.getActiveElements().length > 0 && tooltip.dataPoints.length > 0) {
      const { x: xCoor, y: yCoor } = tooltip.dataPoints[0].element.tooltipPosition(false);

      ctx.save();
      ctx.beginPath();
      ctx.lineWidth = options.lineWidth || 1;
      ctx.strokeStyle = options.lineColor || "#f00";
      ctx.setLineDash(options.lineDashes || [5, 5]);
      ctx.moveTo(xCoor, yCoor);
      ctx.lineTo(xCoor, bottom);
      ctx.moveTo(xCoor, yCoor);
      ctx.lineTo(left, yCoor);
      ctx.stroke();
      ctx.closePath();
      ctx.restore();
    }
  }
};

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    zoom: {
      zoom: {
        wheel: {
          enabled: false
        },
        pinch: {
          enabled: false
        },
        drag: {
          enabled: false
        },
        mode: "x"
      }
    },
    legend: {
      display: true
    },
    chartAreaBorder: {
      lineColor: "#323232"
    },
    hoverLines: {
      lineColor: "#323232",
      lineWidth: 1,
      lineDashes: [3, 3]
    },
    tooltip: {
      backgroundColor: "#000",
      titleAlign: "center",
      titleColor: "#919191",
      titleFont: { weight: "normal", size: 12 },
      footerAlign: "center",
      footerColor: "#919191",
      footerFont: { weight: "normal", size: 12 },
      bodyAlign: "center",
      displayColors: false,
      xAlign: "center",
      yAlign: "bottom",
      //   filter: (tooltipItem) => {
      //     const otherDatasetIndex = tooltipItem.datasetIndex === 0 ? 1 : 0;
      //     // Check if there is a collision by seeing if more than one tooltip is showing
      //     const hasCollision = !!tooltipItem.chart.data.datasets[otherDatasetIndex].data.find((point) => {
      //       return point.y === tooltipItem.raw.y && point.x === tooltipItem.raw.x;
      //     });

      //     // If there's a collision, show only the tooltip of dataset 0
      //     if (hasCollision) {
      //       return tooltipItem.datasetIndex === 0;
      //     }
      //     // If there's no collision, show all tooltips
      //     return true;
      //   },
      callbacks: {
        title: (tooltipItems) => {
          return "PRICE HISTORY";
        },
        label: (tooltipItem) => `$ ${thousandCommas(tooltipItem.raw)}`,
        footer(tooltipItems) {
          return tooltipItems?.[0]?.label;
        }
      }
    }
  },
  scales: {
    x: {
      ticks: {
        display: true,
        color: "black"
      }
    },
    y: {
      ticks: {
        display: true
      }
    }
  },
  transitions: {
    zoom: {
      animation: {
        duration: 1000,
        easing: "easeOutCubic"
      }
    }
  }
};

const MarketplaceItemValuationChart = ({ valuations }) => {
  const monthInMilliseconds = 1000 * 60 * 60 * 24 * 30;

  const chartOptions = {
    scales: {
      x: {
        adapters: {
          date: {
            locale: enUS
          }
        },
        grid: {
          display: false
        },
        type: "time",
        time: {
          unit: "month",
          displayFormats: {
            month: "yyyy-MM-dd"
          },
          tooltipFormat: "yyyy-MM-dd"
        },
        min: formatDate(new Date(new Date(valuations?.[0]?.valuationDatetime || Date.now()).getTime() - 2 * monthInMilliseconds), "YYYY-MM-DD"),
        max: formatDate(new Date(new Date(valuations?.at(-1)?.valuationDatetime || Date.now()).getTime() + 2 * monthInMilliseconds), "YYYY-MM-DD"),
        ticks: {
          color: "black",
          maxTicksLimit: 15
        }
      },
      y: {
        grid: {
          display: false
        },
        ticks: {
          color: "black"
        },
        grace: 500
      }
    }
  };

  const modifiedOptions = merge(options, chartOptions);

  const [mounted, setMounted] = useState();

  useEffect(() => {
    if (typeof window !== `undefined` && !mounted) {
      import("chartjs-plugin-zoom").then((zoomPlugin) => {
        Chart.register(
          zoomPlugin.default,
          PointElement,
          LineElement,
          Tooltip,
          LinearScale,
          TimeScale,
          LogarithmicScale,
          chartAreaBorder,
          pointImagePlugin,
          hoverLines
        );
        setMounted(true);
      });
    }
  }, [window]);

  const chartData = {
    labels: valuations.map(({ valuationDatetime }) => new Date(valuationDatetime)),
    datasets: [
      {
        label: `Price ($USD)`,
        data: valuations.map(({ price }) => price),
        borderColor: "#3DF988",
        borderWidth: 2,
        backgroundColor: "#3DF988",
        pointBorderWidth: 2,
        pointBorderColor: "#3DF988",
        pointRadius: 5,
        pointBackgroundColor: "#3DF988",
        pointHoverRadius: 7,
        pointHoverBackgroundColor: "black",
        pointHoverBorderWidth: 2,
        pointHoverBorderColor: "white",
        tension: 0,
        pointImage: "/images/$.svg",
        pointImageSizes: { width: 10, height: 18 }
      }
    ]
  };

  if (!mounted) return;

  return <Line data={chartData} options={modifiedOptions} />;
};

export default MarketplaceItemValuationChart;
