import { css } from "@emotion/react";
import cn from "classnames";
import useEmblaCarousel from "embla-carousel-react";
import { useEffect, useState } from "react";
import { useApp } from "~hooks";
import Button from "~components/Common/Button";
import CtaButton from "~components/Common/CtaButton";
import SVG from "~components/Common/SVG";
import CtaLink from "~components/CtaLink";
import { useGetBoosts } from "~hooks/useRaffle";
import { buildBgImages } from "~utils/buildBgImages";

const Boosters = ({ data }) => {
  const bgImage = data.backgroundImage?.asset.gatsbyImageData?.images.fallback.src;
  const bgWidth = data.backgroundImage?.asset.gatsbyImageData?.width;
  const mobileBgImage = data.mobileBackground?.asset.gatsbyImageData?.images.fallback.src;

  const [carouselRef, carouselApi] = useEmblaCarousel({ loop: true, slidesToScroll: 1, align: `start` });
  const { raffleId, userIsLoggedIn } = useApp();
  const { boosts, loading } = useGetBoosts(raffleId?.toString());

  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);
  const [isScrollable, setIsScrollable] = useState(false);
  const [selectedSlide, setSelectedSlide] = useState(0);

  useEffect(() => {
    if (carouselApi) {
      const updateCarousel = (api) => {
        const scrollablePrev = api.canScrollPrev();
        const scrollableNext = api.canScrollNext();

        setCanScrollNext(scrollableNext);
        setCanScrollPrev(scrollablePrev);
        setIsScrollable(scrollableNext || scrollablePrev);
        setSelectedSlide(api.selectedScrollSnap());
      };

      carouselApi.on(`init`, updateCarousel);
      carouselApi.on(`reInit`, updateCarousel);
      carouselApi.on(`resize`, updateCarousel);
      carouselApi.on(`scroll`, updateCarousel);

      updateCarousel(carouselApi);
    }
  }, [carouselApi]);

  const isBoostActive = (boostTitle) => {
    switch (boostTitle) {
      case `Early birds (purchases in first 48h)`:
        return boosts.isEarlyBirds;
      case `LCD STAKING`:
        return boosts.isLcdStaker;
      case `LCD Liquidity providers`:
        return boosts.isLiquidityProvider;
      case `LENDING PLATFORM USERS`:
        return boosts.isLendingPlatformUser;
      case `Light & Full Certificate Ownership`:
        return boosts.isNftOwner;
      case `Altr Identity verification`:
        return boosts.isKycd;
      case `Referring`:
        return boosts.isReferrer;
      case `Referred`:
        return boosts.isReferred;
      default:
        return false;
    }
  };

  const activeBoostsCount = Object.values(boosts).filter((boost) => boost).length;
  const totalBoostsCount = Object.keys(boosts).length;

  return (
    <div
      className="boostersSlice"
      css={css`
        ${buildBgImages({ src: bgImage, mobileSrc: mobileBgImage, originalWidth: bgWidth })}
      `}
    >
      <section className="container dark wide">
        <strong className="caption">{data.eyebrow}</strong>
        <h2>{data.title}</h2>
        <div className="boosters">
          {isScrollable && (
            <div className="carouselButtons">
              <Button variant="secondary" disabled={!canScrollPrev} onClick={() => carouselApi?.scrollPrev()}>
                <SVG svg="arrowLeft" />
              </Button>
              <Button variant="secondary" disabled={!canScrollNext} onClick={() => carouselApi?.scrollNext()}>
                <SVG svg="arrowRight" />
              </Button>
            </div>
          )}
          <div className="carouselWrapper">
            <div className="carousel" ref={carouselRef}>
              <ul className="carouselContainer">
                {data.boosters.map((booster) => (
                  <li key={booster._key} className="carouselSlide">
                    <div className={cn(`booster`, isBoostActive(booster.title) && `activeBoost`)}>
                      <strong className="caption">{booster.title}</strong>
                      <div>
                        <p>{booster.rate}</p>
                        <CtaButton cta={booster.link} variant="primary" colorTheme="light" />
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="carouselDots">
            {data.boosters.map((booster, index) => (
              <div key={`dots_${booster._key}`} className={cn(`dot`, selectedSlide === index && `active`)} />
            ))}
          </div>
        </div>
        <p className="description">
          {loading && userIsLoggedIn ? (
            `Loading...`
          ) : (
            <span>
              You have <strong className="activeBoostCount">{activeBoostsCount}</strong>/{totalBoostsCount} boosts active
            </span>
          )}
        </p>
        <CtaLink className="link" cta={data.link} />
      </section>
    </div>
  );
};

export default Boosters;
