import React, { useEffect, useRef, useState } from "react";
import styled from "@emotion/styled";
import cn from "classnames";
import { isDevelopment, formatCryptoCurrency, LCD_ADDRESS, checkRightChain, governanceWebsite, USDT_ADDRESS } from "~utils/helpers";
import { Button, Tooltip } from "~components";
import { useAccount } from "wagmi";
import { breakpoint } from "~utils/css";
import { createPortal } from "react-dom";
import useApp from "~hooks/useApp";
import NoSSR from "~components/Common/NoSSR";

const Modal = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0.8);
`;

const ModalContent = styled.div`
  padding: 20px;
  border-radius: 5px;
  width: 100%;

  ${breakpoint(`tablet`)} {
    max-width: 380px;
  }
`;

const CloseButton = styled.span`
  float: right;
  font-size: 20px;
  font-weight: bold;
  cursor: pointer;
  color: var(--tertiary-color);
`;

const CoinifyModal = ({ closeModal, hashedAddress, currency = `POLYGON` }) => {
  const { userData } = useApp();
  const coinifyPartnerId = process.env.GATSBY_COINIFY_PARTNER_ID;

  const modalRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    }
    // Bind the event listener
    document.addEventListener(`mousedown`, handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener(`mousedown`, handleClickOutside);
    };
  }, [modalRef?.current]);

  currency = isDevelopment ? `USDC` : currency;
  const currencies = [`USDTPOLYGON`, `POLYGON`, ...(isDevelopment ? [`USDC`] : [])];
  const currencyAddresses = currencies.map((curr) => `${curr}:${userData?.address?.toLowerCase()}`);

  return (
    <Modal onClick={() => closeModal()}>
      <ModalContent ref={modalRef}>
        <CloseButton onClick={() => closeModal()}>&times;</CloseButton>
        <iframe
          title="coinify-iframe"
          src={`https://trade-ui${isDevelopment ? `.sandbox` : ``}.coinify.com?partnerId=${coinifyPartnerId}&primaryColor=130C2e&cryptoCurrencies=${currencies}&address=${currencyAddresses}&addressSignature=${hashedAddress}&targetPage=buy&transferInMedia=card,bank&transferOutMedia=blockchain&fiatCurrencies=EUR&defaultCryptoCurrency=${currency}`}
          width="100%"
          height="576px"
          allow="camera;fullscreen;accelerometer;gyroscope;magnetometer"
          allowFullScreen
        />
      </ModalContent>
    </Modal>
  );
};

const QuickSwapModal = ({ closeModal }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        closeModal();
      }
    }
    // Bind the event listener
    document.addEventListener(`mousedown`, handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener(`mousedown`, handleClickOutside);
    };
  }, [modalRef?.current]);

  return (
    <Modal>
      <ModalContent ref={modalRef}>
        <CloseButton onClick={() => closeModal()}>&times;</CloseButton>
        <iframe
          title="quickswap-iframe"
          src={`https://widget.quickswap.exchange/#/?swapIndex=0&currency0=ETH&currency1=${LCD_ADDRESS}`}
          height="660px"
          width="100%"
          style={{
            border: 0,
            margin: `0 auto`,
            marginBottom: `.5rem`,
            display: `block`,
            borderRadius: `10px`,
            maxWidth: `960px`,
            minWidth: `300px`
          }}
        />
      </ModalContent>
    </Modal>
  );
};

const ProfileTopUp = ({ className, hashedAddress }) => {
  const {
    setDisableScroll,
    setWrongChainOverlayData,
    userData: {
      balances: { maticBalance, lcdBalance, usdtBalance }
    }
  } = useApp();

  const { chain } = useAccount();

  const [activeModal, setActiveModal] = useState();

  const handleOpenModal = (modal) => {
    setDisableScroll(true);
    setActiveModal(modal);
  };

  const handleCloseModal = () => {
    setDisableScroll(false);
    setActiveModal(null);
  };

  return (
    <section id="profileBalances" className={cn(`container dark`, className)}>
      {[`POLYGON`, `USDTPOLYGON`].includes(activeModal) && (
        <NoSSR>{createPortal(<CoinifyModal closeModal={handleCloseModal} currency={activeModal} hashedAddress={hashedAddress} />, document.body)}</NoSSR>
      )}
      {activeModal === `LCD` && <NoSSR>{createPortal(<QuickSwapModal closeModal={handleCloseModal} />, document.body)}</NoSSR>}
      <h2 className="title">Balances</h2>
      <p>Explore your financial overview in this section. View and manage your token balances.</p>
      <p>Easily top up your account through our integrated on-chain and off-chain (soon) funding options.</p>
      <ul className="flex-grid">
        <li>
          <dl className="matic">
            <dt className="tag">
              <span>POLYGON (MATIC)</span>
              <Tooltip
                direction="top"
                content={
                  <section className="infoTooltip">
                    <h3>POLYGON (MATIC)</h3>
                    <p>used to pay network transaction fees on the Polygon blockchain on which Altr is built.</p>
                  </section>
                }
              >
                <span className="info" aria-hidden="true" />
              </Tooltip>
            </dt>
            <dd>{formatCryptoCurrency(maticBalance?.formatted || 0)}</dd>
          </dl>
          <Button
            href="https://jumper.exchange/gas/?toChain=137&toToken=0x0000000000000000000000000000000000000000"
            className="topUpButton"
            variant="primary"
            colorTheme="dark"
            fluid
          >
            Top Up
          </Button>
          {/* <Button className="topUpButton" onClick={() => handleOpenModal(`POLYGON`)} variant="primary" colorTheme="dark" fluid>
            Top Up with Credit Card
          </Button> */}
        </li>
        <li>
          <dl className="usdt">
            <dt className="tag">
              <span>USDt</span>
              <Tooltip
                direction="top"
                content={
                  <section className="infoTooltip">
                    <h3>USDt</h3>
                    <p>stablecoin pegged to the US Dollar, used to pay for goods and services hosted on and provided by Altr.</p>
                  </section>
                }
              >
                <span className="info" aria-hidden="true" />
              </Tooltip>
            </dt>
            <dd>{formatCryptoCurrency(usdtBalance?.formatted || 0)}</dd>
          </dl>
          <Button
            href={`https://jumper.exchange/?toChain=137&toToken=${USDT_ADDRESS.toLowerCase()}`}
            className="topUpButton"
            variant="primary"
            colorTheme="dark"
            fluid
          >
            Top Up
          </Button>
          {/* <Button className="topUpButton" onClick={() => handleOpenModal(`USDTPOLYGON`)} variant="primary" colorTheme="dark" fluid>
            Top Up with Credit Card
          </Button> */}
        </li>
        <li>
          <dl className="lcd">
            <dt className="tag">
              <span>LCD</span>
              <Tooltip
                direction="top"
                content={
                  <section className="infoTooltip">
                    <h3>LCD</h3>
                    <p>the governance token of Lucidao, the decentralized organization Altr is part of.</p>
                    <p>
                      Use LCD tokens on <a href={governanceWebsite}>https://governance.lucidao.com</a> to propose and vote for Altr&rsquo;s future developments.
                    </p>
                  </section>
                }
              >
                <span className="info" aria-hidden="true" />
              </Tooltip>
            </dt>
            <dd>{formatCryptoCurrency(lcdBalance?.formatted || 0)}</dd>
          </dl>
          <Button
            href={`https://jumper.exchange/?toChain=137&toToken=${LCD_ADDRESS?.toLowerCase()}`}
            className="topUpButton"
            variant="primary"
            colorTheme="dark"
            fluid
          >
            Top Up
          </Button>
          {/* <Button
            className="topUpButton"
            onClick={() => {
              if (!checkRightChain(chain?.id, setWrongChainOverlayData)) return;
              handleOpenModal(`LCD`);
            }}
            variant="primary"
            colorTheme="dark"
            fluid
          >
            Top Up with Crypto
          </Button> */}
        </li>
      </ul>
    </section>
  );
};

export default ProfileTopUp;
