import { useState } from "react";
import Tabs from "~components/Common/Tabs";
import MarketplaceItemDetails from "../MarketplaceItemDetails";
import MarketplaceItemOrders from "../MarketplaceItemOrders";
import MarketplaceItemCertificate from "../MarketplaceItemCertificate";
import MarketplaceItemGallery from "../MarketplaceItemGallery";
import MarketplaceItemValuation from "../MarketplaceItemValuation";
import { useMarketplaceItemContext } from "../MarketplaceItemContext";

const MarketplaceItemInfo = ({ product, sellOrder }) => {
  const [activeTab, setActiveTab] = useState(`details`);

  const { offers } = useMarketplaceItemContext();

  const handleValuationClick = () => {
    setActiveTab(`valuation`);
  };

  return (
    <div className="itemInfo">
      <Tabs activeTab={activeTab} onChange={(tabId) => setActiveTab(tabId)} colorTheme="light">
        <Tabs.TabsList>
          <Tabs.Tab id="details">
            <span>About</span>
          </Tabs.Tab>
          <Tabs.Tab id="offers">
            <span>Offers</span>
          </Tabs.Tab>
          {product.product.productValueIndexDateAndPrices && product.product.productValueIndexDateAndPrices.length > 0 && (
            <Tabs.Tab id="valuation">
              <span>Price History</span>
            </Tabs.Tab>
          )}
          {product.product.guaranteeCertificate?.publicUrl && (
            <Tabs.Tab id="certificate">
              <span>Authenticity Certificate</span>
            </Tabs.Tab>
          )}
          {product.product.images?.length > 0 && (
            <Tabs.Tab id="gallery">
              <span>Gallery</span>
            </Tabs.Tab>
          )}
        </Tabs.TabsList>
        <Tabs.TabContent id="details">
          <MarketplaceItemDetails
            product={product}
            isWhole={!!sellOrder.order.erc721Token}
            owner={sellOrder.order.maker}
            onValuationClick={handleValuationClick}
          />
        </Tabs.TabContent>
        <Tabs.TabContent id="offers">
          <MarketplaceItemOrders sellOrder={sellOrder} />
        </Tabs.TabContent>
        <Tabs.TabContent id="valuation">
          <MarketplaceItemValuation valuations={product.product.productValueIndexDateAndPrices} />
        </Tabs.TabContent>
        <Tabs.TabContent id="certificate">
          <MarketplaceItemCertificate certificateUrl={product.product.guaranteeCertificate.publicUrl} />
        </Tabs.TabContent>
        <Tabs.TabContent id="gallery">
          <MarketplaceItemGallery images={product.product.images} />
        </Tabs.TabContent>
      </Tabs>
    </div>
  );
};

export default MarketplaceItemInfo;
