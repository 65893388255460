import React from "react";
import cn from "classnames";
import { useAccount, useDisconnect } from "wagmi";
import { useApp } from "~hooks";
import { Link } from "gatsby";

/** ============================================================================
 * @component
 * @param  {string}  	 className				Additional styling config
 * @param  {string}    activeTab				Current active page `settings` | `activity` | `collection`
 * @return {node}
 */
const AccountSwitcher = ({ className, profileActiveTab }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks

  const { disconnect: disconnectWallet } = useDisconnect();
  const { isConnected } = useAccount();
  const { setConnectorActive } = useApp();

  // ---------------------------------------------------------------------------
  // context / ref / state

  // ---------------------------------------------------------------------------
  // variables

  const buttons = [
    {
      title: `Collection`,
      value: `collection`
    },
    {
      title: `Settings`,
      value: `settings`
    },
    {
      title: `Orders`,
      value: `orders`
    },
    {
      title: `Digitise`,
      value: `digitise`
    },
    {
      title: `Balances`,
      value: `balances`
    },
    {
      title: `History`,
      value: `history`
    }
  ];

  // ---------------------------------------------------------------------------
  // methods

  const onClickWallet = () => {
    if (isConnected) {
      disconnectWallet();
    } else {
      setConnectorActive(true);
    }
  };

  // ---------------------------------------------------------------------------
  // render

  return (
    <nav id="profileNavigation" className={className}>
      <ul className="hiddenScroll">
        {buttons.map((button) => (
          <li key={button.value}>
            <Link className={cn(profileActiveTab === button.value && `active`)} to={`/profile/${button.value}`}>
              {button.title}
            </Link>
          </li>
        ))}
      </ul>

      <button type="button" onClick={onClickWallet}>
        <span>Wallet:</span>
        {isConnected ? <span className="status connected">Signed In</span> : <span className="status disconnected">Signed Out</span>}
      </button>
    </nav>
  );
};

export default AccountSwitcher;
