import React, { useCallback, useEffect, useState } from "react";
import { useNFT } from "~hooks";
import { Image, ProfileItemActionOverlays, NFTActivityControls, ProfileItemDetails, ProfileItemHistory, ProfileItemInspector, SVG, Tabs } from "~components";
import useApp from "~hooks/useApp";
import { handleImageClick } from "~utils/helpers";

const ProfileCollectionItem = ({ item, certificationRequestDetails, onBack = () => {}, readOnly }) => {
  // ---------------------------------------------------------------------------
  // imports / hooks
  const isWhole = item?.tokenType === `ERC721`;
  const isCertificate = !!item?.raw.metadata?.certificatetype;
  const isLightCertificate = item?.raw.metadata?.certificatetype?.toLowerCase() === `light`;
  const isFullCertificate = item?.raw.metadata?.certificatetype?.toLowerCase() === `full`;

  const nft = useNFT(isLightCertificate ? null : isWhole ? item?.raw.metadata?.nonce : item?.raw.metadata?.productIdentifier);

  const { kycData } = useApp();

  const isKycd = kycData.kycStatus === `Whitelisted`;

  // ---------------------------------------------------------------------------
  // context / ref / state

  const [activeTab, setActiveTab] = useState(`details`);
  const [overlayActive, setOverlayActive] = useState(false);
  const [hasNavigated, setHasNavigated] = useState(false);

  const showActivityControls = activeTab === `actions`;

  // ---------------------------------------------------------------------------
  // methods

  const onTabChange = useCallback(
    (tabId) => {
      setActiveTab(tabId);
    },
    [setActiveTab]
  );

  // This must be done in useEffect in order to preserve proper hydration
  useEffect(() => setHasNavigated(!!window.hasNavigated), []);

  // ---------------------------------------------------------------------------
  // render

  if (!item) {
    return null;
  }

  return (
    <>
      <ProfileItemInspector item={item} active={overlayActive} setActive={setOverlayActive} />

      <ProfileItemActionOverlays nft={nft} />

      <section id="profileCollectionItem" className="profilePane">
        <Tabs defaultActiveTab="details" onChange={onTabChange}>
          {hasNavigated && (
            <button className="back aside" type="button" onClick={onBack}>
              <SVG svg="arrowBack" />
              <span>Back</span>
            </button>
          )}
          {!readOnly && !isLightCertificate && (
            <Tabs.TabsList className="main profileTabs">
              <Tabs.Tab id="details">
                <span>Overview</span>
              </Tabs.Tab>
              <Tabs.Tab id="history">
                <span>Transaction History</span>
              </Tabs.Tab>
            </Tabs.TabsList>
          )}
          <section className="profileItemOverview aside">
            <figure>
              <a href={item.image.cachedUrl} target="_blank" rel="noreferrer" onClick={(e) => handleImageClick(e, item.image.cachedUrl)}>
                <span className="visually-hidden">{`${item?.name} image`}</span>
                <Image image={item.image.cachedUrl} fill />
              </a>
            </figure>
            {!readOnly && isLightCertificate && !isKycd && <p className="caption kycNotice">Perform Identity Verification to Request Full Certificate</p>}
          </section>
          <section className="profileItemTabs main">
            <Tabs.TabContent id="details">
              <ProfileItemDetails
                item={item}
                nft={nft}
                isCertificate={isCertificate}
                isFullCertificate={isFullCertificate}
                certificationRequestDetails={certificationRequestDetails}
                readOnly={readOnly}
              />
            </Tabs.TabContent>
            <Tabs.TabContent id="history">
              <ProfileItemHistory item={item} />
            </Tabs.TabContent>
          </section>
        </Tabs>

        {showActivityControls && isCertificate && !isFullCertificate && (
          <div className="profileItemActivity">
            <NFTActivityControls nft={nft} page="collection" />
          </div>
        )}
      </section>
    </>
  );
};

export default ProfileCollectionItem;
