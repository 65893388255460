import { createContext, useCallback, useContext, useEffect, useMemo, useState } from "react";
import { blockchainHooks } from "~hooks/blockchainHooks";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import { genericErrorCallback, walletAddressAreEqual } from "~utils/helpers";

const MarketplaceItemContext = createContext(null);

export const useMarketplaceItemContext = () => {
  const context = useContext(MarketplaceItemContext);

  if (!context) {
    throw new Error(`Use useMarketplaceItemContext within MarketplaceItemContext`);
  }

  return context;
};

const MarketplaceItemProvider = ({ children, nftData }) => {
  const [selectedOffer, setSelectedOffer] = useState(0);
  const [offers, setOffers] = useState([]);
  const [refetch, doRefetch] = useState(false);

  const { trader } = useExternalIntegrations();
  const { useGetNftOwnerOf } = blockchainHooks();

  const { data: owner } = useGetNftOwnerOf(nftData);

  useEffect(() => {
    const getOffers = async () => {
      const traderSdk = trader();
      const productOffers = await traderSdk.getOrdersByCollectionAddressAndTokenId(nftData.nftCollectionInfo.collectionAddress, nftData.tokenId, {
        status: `open`,
        nftType: `ERC721`,
        sellOrBuyNft: `buy`
      });

      setOffers(
        productOffers.orders
          .filter((order) => !walletAddressAreEqual(order.order.maker, owner))
          .sort((a, b) => Number(b.erc20TokenAmount) - Number(a.erc20TokenAmount))
      );
    };

    getOffers().catch(genericErrorCallback);
  }, [nftData.nftCollectionInfo.collectionAddress, nftData.tokenId, refetch, owner]);

  const handleSetOffer = useCallback((id) => {
    setSelectedOffer(id);
  }, []);

  const contextValue = useMemo(
    () => ({
      offers,
      selectedOffer: { ...offers[selectedOffer], id: selectedOffer },
      setSelectedOffer: handleSetOffer,
      refetch: () => doRefetch((prev) => !prev)
    }),
    [offers.length, selectedOffer, handleSetOffer]
  );

  return <MarketplaceItemContext.Provider value={contextValue}>{children}</MarketplaceItemContext.Provider>;
};

export default MarketplaceItemProvider;
