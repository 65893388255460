import React from "react";
import BuyAction from "./BuyAction";
import OfferAction from "./OfferAction";
import SellAction from "./SellAction";
import OfferOverview from "./OfferOverview";
import useApp from "~hooks/useApp";

const MarketplaceItemActions = ({ product, sellOrder, isOwner }) => {
  const { userIsLoggedIn } = useApp();

  return (
    <div className="actions">
      {isOwner ? (
        <>
          <SellAction sellOrder={sellOrder} product={product} />
          <OfferOverview sellOrder={sellOrder} />
        </>
      ) : (
        <>
          <BuyAction product={product} sellOrder={sellOrder} />
          {userIsLoggedIn && <OfferAction />}
        </>
      )}
    </div>
  );
};

export default MarketplaceItemActions;
