import { useAccount, useReadContracts } from "wagmi";
import { useEffect, useState } from "react";
import { useApp } from "~hooks";
import { keccak256, parseEther } from "viem";
import { blockchainHooks, blockchainHooksHelper } from "./blockchainHooks";
import { raffleTicketPurchaseAbi } from "~constants/raffleTicketPurchaseAbi";
import { raffleRewarderAbi } from "~constants/raffleRewarderAbi";
import useExternalIntegrations from "./useExternalIntegrations";

const { useContractWriteHelper, useContractReadHelper } = blockchainHooksHelper();
const { useApprove } = blockchainHooks();

// Raffle Ticket Purchase Contract Hooks
export const usePurchaseTicket = (raffleTicketPurchaseAddress, ticketAmount, referralCode = `000000`) => {
  const contractAbiData = { contractAddress: raffleTicketPurchaseAddress, abi: raffleTicketPurchaseAbi };

  return useContractWriteHelper(contractAbiData, `purchaseTickets`, [ticketAmount, referralCode]);
};

export const useApproveRaffleTicketPurchase = (tokenAddress, raffleTicketPurchaseAddress, approveAmount) =>
  useApprove(tokenAddress, raffleTicketPurchaseAddress, approveAmount);

export const useClaimRefund = (raffleTicketPurchaseAddress) => {
  const contractAbiData = { contractAddress: raffleTicketPurchaseAddress, abi: raffleTicketPurchaseAbi };

  return useContractWriteHelper(contractAbiData, `claimRefund`, []);
};

export const useTicketsPurchased = (raffleTicketPurchaseAddress) => {
  const { address } = useAccount();
  const contractAbiData = { contractAddress: raffleTicketPurchaseAddress, abi: raffleTicketPurchaseAbi };

  return useContractReadHelper(contractAbiData, `ticketsPurchased`, [address]);
};

export const useReadRaffleTicketPurchaseParam = (raffleTicketPurchaseAddress) => {
  const contractAbiData = { address: raffleTicketPurchaseAddress, abi: raffleTicketPurchaseAbi };

  const functionNames = [
    `ticketPrice`,
    `startTimestamp`,
    `finishTimestamp`,
    `minTickets`,
    `maxTickets`,
    `totalTicketsSold`,
    `personalMaxTickets`,
    `purchaseToken`
  ];

  const { data, ...res } = useReadContracts({
    contracts: functionNames.map((functionName) => ({ ...contractAbiData, functionName }))
  });

  return {
    ...res,
    data: functionNames.reduce((acc, functionName, i) => {
      acc[functionName] = data?.[i];
      return acc;
    }, {})
  };
};

export const useVerificationDeadline = (raffleRewarderAddress) => {
  const contractAbiData = { contractAddress: raffleRewarderAddress, abi: raffleRewarderAbi };

  return useContractReadHelper(contractAbiData, `deadline`, []);
};

export const useClaimPrize = (raffleRewarderAddress) => {
  const contractAbiData = { contractAddress: raffleRewarderAddress, abi: raffleRewarderAbi };

  return useContractWriteHelper(contractAbiData, `claimPrize`, []);
};

export const useGetBoosts = (raffleId) => {
  const { userData, userIsLoggedIn, raffleReferralCode } = useApp();
  const { graph, api } = useExternalIntegrations();

  const [boosts, setBoosts] = useState({
    isLcdStaker: false,
    isLendingPlatformUser: false,
    isNftOwner: false,
    isKycd: false,
    isReferrer: false,
    isReferred: false,
    isEarlyBirds: false,
    isLiquidityProvider: false
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getUserBoosts = async () => {
      setLoading(true);
      try {
        if (!userData || !userData.address || !userIsLoggedIn || raffleId === null || raffleId === undefined || !raffleReferralCode) {
          setBoosts({
            isLcdStaker: false,
            isLendingPlatformUser: false,
            isNftOwner: false,
            isKycd: false,
            isReferrer: false,
            isReferred: false,
            isEarlyBirds: false,
            isLiquidityProvider: false
          });
          setError(`Missing required user data or raffle ID`);
          setLoading(false);
          return;
        }

        const minLiquidityProvisioning = 1000; // FIXME: get minLiquidityProvisioning once known where is it
        const minStakingBoostAmount = parseEther(`25000`);
        const earlyBoostTimeWindow = 172800; // 2 days

        const raffle = await graph.getRaffleById(raffleId);
        const endEarlyBirdTimestamp = (Number(raffle.startTimestamp?.toString() || 0) + earlyBoostTimeWindow).toString();

        const [{ user, staker, loans, referral, purchaseEvents }, { data: liquidity }] = await Promise.all([
          graph.getRaffleBoosts(userData.address, raffleReferralCode, raffleId, endEarlyBirdTimestamp),
          api.getUserLiquidityProvisioning()
        ]);

        const isLcdStaker = BigInt(staker?.stakeAmount || 0) >= minStakingBoostAmount;
        const isNftOwner = user?.erc721Owned?.length > 0;
        const isLendingPlatformUser = loans?.length > 0;
        const isKycd = user?.allowed || false;
        const isReferrer = Number(referral?.referralAmount || 0) > 0;
        const isReferred = !!purchaseEvents?.find((event) => event.referrer !== keccak256(`000000`) && event.referrer !== keccak256(raffleReferralCode))
          ?.referrer;
        const isEarlyBirds = purchaseEvents?.length > 0;
        const isLiquidityProvider = Object.values(liquidity).reduce((acc, val) => acc + Number(val?.userTvl || 0), 0) >= minLiquidityProvisioning;

        setBoosts({
          isLcdStaker,
          isLendingPlatformUser,
          isNftOwner,
          isKycd,
          isReferrer,
          isReferred,
          isEarlyBirds,
          isLiquidityProvider
        });
        setLoading(false);
      } catch (err) {
        console.error(`Error fetching boosts:`, err);
        setError(`Error fetching boosts`);
      }
    };

    getUserBoosts().catch((err) => {
      console.error(`Error fetching boosts:`, err);
    });
  }, [userData?.address, userIsLoggedIn, raffleReferralCode, raffleId]);

  return { boosts, loading, error }; // cumulativeBoost: Object.values(boosts).reduce((acc, curr) => acc + (curr % 100), 100) };
};
