/* eslint-disable import/no-extraneous-dependencies */

import React from "react";
import { ProfileIdentityVerification, ProfileUserData } from "~components";
import { useApp } from "~hooks";

/** ============================================================================
 * @component
 */
const ProfileSettings = () => {
  const { userData, kycData, setUserData } = useApp();
  const { kycSession } = kycData;

  return (
    <section id="profileSettings">
      <h2 className="title">My Data</h2>
      <ProfileUserData kycSession={kycSession} userData={userData} setUserData={setUserData} />
      <ProfileIdentityVerification kycSession={kycSession} userData={userData} />
    </section>
  );
};

export default ProfileSettings;
