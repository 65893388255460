import React from "react";
import styled from "@emotion/styled";
import {
  NFTOverlayBuyout,
  NFTOverlayBuyoutQuote,
  NFTOverlayCompletion,
  NFTOverlayConvertFragmentToWhole,
  NFTOverlayCreateFragmentSellOrder,
  NFTOverlayCreateWholeSellOrder,
  NFTOverlayProcessing,
  NFTOverlayTransferFragment,
  NFTOverlayTransferWhole,
  NFTOverlayClaimWhole,
  NFTOverlayClaimRequestApproveCurrency,
  NFTOverlayClaimRequestPayFee,
  NFTOverlayClaimRequestClaim,
  NFTOverlayOfferWhole,
  NFTOverlayPurchaseWholeFromSellOrder,
  NFTOverlayPurchaseFragmentFromSellOrder,
  NFTOverlayPurchaseFragmentFromOwner,
  NFTOverlaySellWholeFromBuyOrder,
  NFTOverlaySellFragmentFromBuyOrder
} from "~components";
import useApp from "~hooks/useApp";

/** ============================================================================
 * @css
 */

const Container = styled.div`
  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  right: 0;
  pointer-events: none;
  z-index: 100;
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const ProfileItemActionOverlays = ({ nft }) => {
  const { overlayActivityData, activeOverlay } = useApp();

  const getActiveOverlay = () => {
    switch (activeOverlay) {
      case `NFTOverlayOfferWhole`:
        return <NFTOverlayOfferWhole nft={nft} activeOwner={overlayActivityData?.owner} />;
      case `NFTOverlayPurchaseFragmentFromOwner`:
        return <NFTOverlayPurchaseFragmentFromOwner nft={nft} activeOwner={overlayActivityData?.owner} />;
      case `NFTOverlayCreateFragmentSellOrder`:
        return <NFTOverlayCreateFragmentSellOrder nft={nft} activeOwner={overlayActivityData?.owner} />;
      case `NFTOverlayCreateWholeSellOrder`:
        return <NFTOverlayCreateWholeSellOrder nft={nft} activeOwner={overlayActivityData?.owner} />;
      case `NFTOverlayPurchaseWholeFromSellOrder`:
        return <NFTOverlayPurchaseWholeFromSellOrder nft={nft} activeOrder={overlayActivityData?.order} />;
      case `NFTOverlaySellWholeFromBuyOrder`:
        return <NFTOverlaySellWholeFromBuyOrder nft={nft} activeOrder={overlayActivityData?.order} />;
      case `NFTOverlayPurchaseFragmentFromSellOrder`:
        return <NFTOverlayPurchaseFragmentFromSellOrder nft={nft} activeOrder={overlayActivityData?.order} />;
      case `NFTOverlaySellFragmentFromBuyOrder`:
        return <NFTOverlaySellFragmentFromBuyOrder nft={nft} activeOrder={overlayActivityData?.order} />;
      case `NFTOverlayBuyoutQuote`:
        return <NFTOverlayBuyoutQuote nft={nft} />;
      case `NFTOverlayBuyout`:
        return <NFTOverlayBuyout nft={nft} />;
      case `NFTOverlayTransferFragment`:
        return <NFTOverlayTransferFragment nft={nft} />;
      case `NFTOverlayTransferWhole`:
        return <NFTOverlayTransferWhole nft={nft} />;
      case `NFTOverlayClaimWhole`:
        return <NFTOverlayClaimWhole nft={nft} />;
      case `NFTOverlayConvertFragmentToWhole`:
        return <NFTOverlayConvertFragmentToWhole nft={nft} />;
      case `NFTOverlayClaimRequestApproveCurrency`:
        return <NFTOverlayClaimRequestApproveCurrency nft={nft} />;
      case `NFTOverlayClaimRequestPayFee`:
        return <NFTOverlayClaimRequestPayFee nft={nft} />;
      case `NFTOverlayClaimRequestClaim`:
        return <NFTOverlayClaimRequestClaim nft={nft} />;
      default:
        return null;
    }
  };

  return (
    <Container>
      <NFTOverlayCompletion />
      <NFTOverlayProcessing />
      {nft?.enrichedProduct && getActiveOverlay()}
    </Container>
  );
};

export default ProfileItemActionOverlays;
