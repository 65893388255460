import React, { useState } from "react";
import { useAccount, usePublicClient, useWalletClient } from "wagmi";
import { Button } from "~components";
import { TARGET_CHAIN } from "~utils/helpers";
import useExternalIntegrations from "~hooks/useExternalIntegrations";
import useApp from "~hooks/useApp";
import SwitchChainButton from "~components/Common/SwitchChainButton";
import { handleError } from "~utils/error";
import Table from "~components/Common/Table";

const ProfileOrderCard = ({ data }) => {
  const {
    setOrderCanceled,
    orderCanceled,
    setOverlayCompletionData,
    userData: {
      balances: { maticBalance }
    }
  } = useApp();
  const { chain } = useAccount();
  const publicClient = usePublicClient();
  const { data: walletClient } = useWalletClient();
  const { trader } = useExternalIntegrations();
  const traderSdk = trader(publicClient, walletClient);

  const [executing, setExecuting] = useState(false);

  const handleOrderClick = async (order) => {
    setExecuting(true);
    try {
      await traderSdk.cancelOrder(order);

      setOrderCanceled(!orderCanceled);
    } catch (error) {
      handleError(error, setOverlayCompletionData, maticBalance?.value);
      console.log(error);
    }
    setExecuting(false);
  };

  // todo: more validation?
  if (!data?.id && data?.id !== 0) {
    return null;
  }

  const additionalContent = (
    <>
      {data.nftTokenType === `ERC1155` && (
        <dl>
          <dt>Unit price</dt>
          <dd>{data.unitPrice}</dd>
        </dl>
      )}
      {data.nftTokenType === `ERC721` && (
        <dl>
          <dt>After fees</dt>
          <dd>{data.totalAfterFees}</dd>
        </dl>
      )}
      {data?.order &&
        (chain?.id === TARGET_CHAIN?.id ? (
          <Button variant="secondary" fluid onClick={() => handleOrderClick(data?.order)} className={executing && `loading`}>
            Cancel
          </Button>
        ) : (
          <SwitchChainButton fluid variant="secondary" />
        ))}
    </>
  );

  return (
    <Table.Row className="profileOrderCard profileListItem accordion" additionalContent={additionalContent}>
      <Table.Cell as="dl" className="title">
        <dt>{data.type}</dt>
        <dd>{data.item.title || data.item.name}</dd>
      </Table.Cell>
      <Table.Cell as="dl">
        <dt>Price</dt>
        <dd>{data.total}</dd>
      </Table.Cell>
      <Table.Cell as="dl">
        <dt>Amount</dt>
        <dd>{data.nftTokenType === `ERC721` ? `Whole` : `${data.amount} ${data.amount > 1 ? `Fragments` : `Fragment`}`}</dd>
      </Table.Cell>
      <Table.Cell as="dl" className="status">
        <dt>
          <span>{data.type}</span>
        </dt>
        <dd>{data.expires}</dd>
      </Table.Cell>
    </Table.Row>
  );
};

export default ProfileOrderCard;
