import React from "react";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, Grid } from "~components";
import { useLcdPrice } from "~hooks";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.section`
  width: 100%;
  height: auto;
  position: relative;
  overflow: hidden;
  background: ${({ background }) => `${background}`};
  color: ${({ color }) => `${color}`};

  ${breakpoint(`large-tablet`)} {
    height: 113px;
  }
`;

//
// coin price

const LucidaoPrice = styled.div`
  grid-column: 1 / -1;
  position: relative;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem 0;

  // todo: this should break out of the grid
  border-top: 1px solid var(--color-mono-60);

  ${breakpoint(`large-tablet`)} {
    grid-column: span 5 / span 5;
    align-items: center;
    padding: 2rem 0;
    border: none;
  }

  ${breakpoint(`large-desktop`)} {
    grid-column: span 4 / span 4;
  }
`;

const LucidaoPriceLabel = styled.h3`
  margin-bottom: 0.25rem;
  color: var(--color-mono-40);
`;

/** ============================================================================
 * @component
 */
const PriceTicker = ({ data: { backgroundColour, fontColour, lcdURL, urlOverride } }) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const buyLink = urlOverride || lcdURL?.link;

  const { lcdPrice } = useLcdPrice();

  // ---------------------------------------------------------------------------
  // render

  return (
    <Container className="container" background={backgroundColour?.hex || `#ffffff`} color={fontColour?.hex || `#000000`}>
      {lcdPrice && (
        <Grid node="article">
          <LucidaoPrice>
            <div>
              <LucidaoPriceLabel className="caption">Lucidao (LCD)</LucidaoPriceLabel>

              {/* todo: how do we get the current LCD price? */}
              <h2 className="h4">{`$${lcdPrice ?? 0.555}`}</h2>
            </div>

            {buyLink?.url && buyLink?.text && (
              <Button
                css={css`
                  width: auto;
                  height: 42px; // todo: check against designs
                `}
                to={buyLink.url}
                color="white"
                transparent
              >
                <span className="button-text">{buyLink.text}</span>
              </Button>
            )}
          </LucidaoPrice>
        </Grid>
      )}
    </Container>
  );
};

export default PriceTicker;
