import React from "react";
import styled from "@emotion/styled";
import cn from "classnames";
import { Button, Tooltip } from "~components";
import { useLcdPrice } from "~hooks";
import { LCD_ADDRESS, lucidaoWebsite } from "~utils/helpers";
import { breakpoint } from "~utils/css";

/** ============================================================================
 * @css
 */
const Container = styled.div`
  display: none;
  padding: 1rem 1.5rem;
  border-radius: 12px;
  align-items: center;
  transition: opacity 2s;
  opacity: ${({ visible }) => (visible ? 1 : 0)};

  color: ${({ colorTheme }) => (colorTheme === `light` ? `var(--color-white)` : `var(--color-black)`)};

  .currencyTickerTitle {
    margin-bottom: 4px;
  }

  ${breakpoint(`large-tablet`)} {
    grid-column: 1 / span 6;
    display: flex;
  }
`;

const Content = styled.div`
  margin-right: 60px;
  flex-shrink: 0;

  .tip p {
    line-height: 1;
  }
`;

const Flex = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.75rem;
  justify-content: space-between;
`;

const Percentage = styled.div`
  color: ${({ isIncrease }) => (isIncrease ? `var(--color-ux-green-100)` : `var(--color-ux-error)`)};

  display: flex;
  align-items: center;
  margin-left: 10px;

  .currencyTickerPercentageTriangle {
    height: 0;
    width: 0;
    margin-right: 4px;
    border-left: 0.5rem solid transparent;
    border-right: 0.5rem solid transparent;
    transform: ${({ isIncrease }) => (isIncrease ? `rotate(0)` : `rotate(180deg)`)};
    border-bottom: 0.75rem solid ${({ isIncrease }) => (isIncrease ? `var(--color-ux-green-100)` : `var(--color-ux-error)`)};
  }
`;

const ButtonContainer = styled.div`
  display: inline;
  flex-shrink: 0;
`;

/** ============================================================================
 * @component
 * @param  {string}    title   				Top text
 * @param  {string}    price   				Price of token
 * @param  {number}    percent   			Percentage increase or decrease
 * @param  {string}    ctaText   			Button text
 * @param  {string}    colorThene   	`light` or `dark`
 * @param  {string}    className   	  Classname for additional styling config
 * @return {node}
 */

const CurrencyTicker = ({ colorTheme, className }) => {
  const { lcdPrice, hasFetched } = useLcdPrice();

  const percent = null;
  const isIncrease = percent > 0;

  return (
    <Container visible={hasFetched} colorTheme={colorTheme} className={cn(colorTheme === `light` ? `frosted-glass-dark` : `frosted-glass-light`, className)}>
      <Flex>
        <Content>
          <p className="caption currencyTickerTitle">
            <a className="caption" target="_blank" href={lucidaoWebsite} rel="noreferrer">
              Powered by Lucidao (LCD)
            </a>
          </p>
          <Flex className="b1">
            <Tooltip content="Data provided by CoinGecko" direction="right">
              <span>${lcdPrice || `Error fetching price`}</span>
            </Tooltip>
            {percent && (
              <Percentage isIncrease={isIncrease}>
                <div className="currencyTickerPercentageTriangle" />
                <span>{percent}%</span>
              </Percentage>
            )}
          </Flex>
        </Content>
        <ButtonContainer>
          <Button href={`https://jumper.exchange/?toChain=137&toToken=${LCD_ADDRESS}`} variant="secondary" colorTheme={colorTheme}>
            {/* Double span is needed to avoid flexbox making all its children a block elements */}
            <span>
              <span className="tag">Buy LCD</span>
            </span>
          </Button>
        </ButtonContainer>
      </Flex>
    </Container>
  );
};

export default CurrencyTicker;
