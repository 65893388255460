import React from "react";
import styled from "@emotion/styled";
import { NFTOverlayCompletion, NFTOverlayProcessing, EventOverlayPurchase, EventOverlayPurchaseFiat } from "~components";

/** ============================================================================
 * @css
 */

const Container = styled.div`
  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  right: 0;
  pointer-events: none;
  z-index: 100;
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const EventOverlays = ({ nft, eventProduct, displayPrice, discountPrice, isDiscountEligible, selected, ticketPrice }) => (
  <Container>
    <NFTOverlayProcessing />
    <NFTOverlayCompletion />

    {nft?.enrichedEvent && (
      <>
        <EventOverlayPurchase
          nft={nft}
          sanityProduct={eventProduct}
          discountPrice={discountPrice}
          isDiscountEligible={isDiscountEligible}
          selected={selected}
          ticketPrice={ticketPrice}
        />
      </>
    )}
    <EventOverlayPurchaseFiat nft={nft} sanityProduct={eventProduct} displayPrice={displayPrice} selected={selected} />
  </Container>
);

export default EventOverlays;
