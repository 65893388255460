/* eslint-disable import/no-extraneous-dependencies */

import React, { useMemo, useState } from "react";
import cn from "classnames";
import { ProfileOrderCard, LoadingSpinner } from "~components";
import { formatDateTime, formatStablecoin, formatCryptoCurrency } from "~utils/helpers";
import useApp from "~hooks/useApp";
import { useProfileData } from "~context/ProfileContext";
import Tabs from "~components/Common/Tabs";
import Table from "~components/Common/Table";
import ProfileCollectionFilters from "../ProfileCollection/ProfileCollectionFilters";
import { useParseTraderOrder } from "~hooks/useParseTraderOrder";

const ITEMS_PER_PAGE = 10;

const ProfileOrders = ({ className }) => {
  const { userData } = useApp();
  const { traderOrders = [], isLoadingOrders: isLoading } = useProfileData();
  const [searchQuery, setSearchQuery] = useState(``);
  const [sorting, setSorting] = useState(`latest`);

  const { getPriceWithFees, getPriceWithMakerFee, getPriceWithTakerFee } = useParseTraderOrder();

  const tabs = [
    {
      title: `All`,
      value: `all`
    },
    {
      title: `Buy`,
      value: `buy`
    },
    {
      title: `Sell`,
      value: `sell`
    }
  ];

  const orders = useMemo(
    () =>
      traderOrders
        .map((order, i) => {
          if (!order?.order) {
            return null;
          }
          const chargeFee = order?.sellOrBuyNft === `sell`;
          const parsedOrder = {
            id: i,
            type: order.sellOrBuyNft,
            nftTokenType: order.nftType,
            item: { title: order.order.productName },
            expires: formatDateTime(order.order.expiry * 1000, `DD-MM-YYYY HH:mm`),
            amount: order?.nftTokenAmount,
            unitPrice: `${formatCryptoCurrency(
              formatStablecoin(BigInt(chargeFee ? getPriceWithMakerFee(order?.order) : order?.erc20TokenAmount) / BigInt(order?.nftTokenAmount), 6)
            )}`,
            total: `${formatCryptoCurrency(formatStablecoin(chargeFee ? getPriceWithMakerFee(order?.order) : getPriceWithTakerFee(order?.order)))}`,
            totalAfterFees: `${formatCryptoCurrency(formatStablecoin(chargeFee ? order?.erc20TokenAmount : getPriceWithFees(order?.order)))}`,
            order
          };
          return parsedOrder;
        })
        .filter((x) => x !== null),
    [traderOrders, userData?.address, userData?.user?.walletAddress]
  );

  const separatedOrders = orders?.reduce((acc, curr) => {
    const { all = [], [curr.type]: currentTypeTab = [] } = acc || {};

    return { ...acc, all: [...all, curr], [curr.type]: [...currentTypeTab, curr] };
  }, {});

  const filteredOrders = Object.entries(separatedOrders).reduce(
    (acc, [orderType, typeOrders]) => ({
      ...acc,
      [orderType]: typeOrders
        .filter((order) => (order.item.title || order.item.name)?.toLowerCase().includes(searchQuery.toLowerCase()))
        .sort((a, b) => {
          const timeA = new Date(a.expires).getTime();
          const timeB = new Date(b.expires).getTime();

          if (sorting === `latest`) return timeB - timeA;

          return timeA - timeB;
        })
    }),
    {}
  );

  const dropdowns = [
    {
      label: `Sort by`,
      options: [
        { value: `latest`, title: `Latest` },
        { value: `oldest`, title: `Oldest` }
      ],
      onChange: setSorting,
      value: sorting,
      defaultValue: `latest`
    }
  ];

  return (
    <div>
      <section id="profileOrders" className={cn(className)}>
        <Tabs defaultActiveTab="all">
          <Tabs.TabsList className="profileTabs">
            {tabs.map((tab) => (
              <Tabs.Tab key={`tab_${tab.value}`} id={tab.value}>
                <span>{`${tab.title} (${tab.value === `all` ? orders.length : separatedOrders[tab.value]?.length || 0})`}</span>
              </Tabs.Tab>
            ))}
          </Tabs.TabsList>
          {tabs.map((tab) => (
            <Tabs.TabContent key={`tab_content_${tab.value}`} id={tab.value}>
              <h2 className="title">{`${tab.title} orders`}</h2>
            </Tabs.TabContent>
          ))}
          <div className="profileList">
            {tabs.map((tab) => (
              <Tabs.TabContent key={`tab_content_${tab.value}`} id={tab.value}>
                <ProfileCollectionFilters
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  pageNumber={Math.ceil((filteredOrders[tab.value]?.length || 0) / ITEMS_PER_PAGE)}
                  displayedPages={5}
                  dropdowns={dropdowns}
                />
                {!!filteredOrders[tab.value]?.length && (
                  <Table>
                    {filteredOrders[tab.value].map((order) => (
                      <ProfileOrderCard key={`order_${order.id}`} data={order} />
                    ))}
                  </Table>
                )}
                {!isLoading && !filteredOrders[tab.value]?.length && <p>No orders to show</p>}
              </Tabs.TabContent>
            ))}
            {isLoading && <LoadingSpinner className="loader" isInline />}
          </div>
        </Tabs>
      </section>
    </div>
  );
};

export default ProfileOrders;
