/* eslint-disable react/no-danger */
import { polygon, polygonAmoy } from "wagmi/chains";
import { Network } from "alchemy-sdk";
import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import { has as lodashHas } from "lodash";
import { formatEther, formatUnits } from "viem";
import { GetAllRaffleBoosts, GetRaffleBuyers, GetRaffleById } from "~dApp/GraphQuery";

dayjs.extend(duration);
dayjs.extend(relativeTime);
export const BUYTOKEN_DENOMINATOR = 10 ** 6; // FIXME: should be set in a utils file

// general

export const capitalizeString = (str, lower = false) => (lower ? str.toLowerCase() : str).replace(/(?:^|\s|["'([{])+\S/g, (match) => match.toUpperCase());

export const getRandomIntByRange = (min, max) => Math.floor(Math.random() * (max - min + 1) + min);

export const remToPx = (rem) => {
  if (typeof window === `undefined`) {
    return rem;
  }

  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export function splitStringToParagraphs(string, className) {
  if (!className) {
    className = ``;
  }

  const splitText = string?.split(/\r?\n/);
  const jsx = [];

  splitText?.forEach((row, index) => {
    const rowIndex = index;

    if (row !== ``) {
      jsx.push(<p key={`split-text-${rowIndex}`} className={className} dangerouslySetInnerHTML={{ __html: row }} />);
    }
  });

  return jsx;
}

export const thousandCommas = (x) => x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, `,`);
export const formatCryptoCurrency = (x) => thousandCommas(parseFloat(x).toFixed(2));
export const formatStablecoin = (x) => formatUnits(x, 6);

export const truncateAddress = (address, startChars = 3, endChars = 3) => {
  if (!address) return `No Account`;
  const match = address.match(new RegExp(`^(0x[a-zA-Z0-9]{${startChars}})[a-zA-Z0-9]+([a-zA-Z0-9]{${endChars}})$`));
  if (!match) return address;
  return `${match[1]}…${match[2]}`;
};

export const truncateTxId = (txId) => {
  if (!txId) return `No TxID`;
  const match = txId.match(/^(0x[a-zA-Z0-9]{10})[a-zA-Z0-9]+([a-zA-Z0-9]{10})$/);
  if (!match) return txId;
  return `${match[1]}…${match[2]}`;
};

export const truncateText = (text, chars = 600) => (text.length > chars ? `${text.substr(0, chars)}\u2026` : text);

export const getFallbackImageSrc = (image) => image?.asset?.gatsbyImage?.images?.fallback?.src || null;

export const ipfsIoFallback = (url) => {
  return url?.replace("https://ipfs.io/ipfs/", process.env.GATSBY_IPFS_FALLBACK);
};

export const generateSlug = (name) => {
  let slug = name.replace(/\s+/g, `-`).toLowerCase();

  slug = slug.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, ``);

  return slug;
};

//
// dapp

export const isDevelopment = process.env.NODE_ENV === `development` || process.env.GATSBY_ENV === `development`;

export const TARGET_CHAIN = isDevelopment ? polygonAmoy : polygon;
export const TARGET_CHAIN_ALCHEMY = isDevelopment ? Network.MATIC_AMOY : Network.MATIC_MAINNET;

export const BLOCK_EXPLORER = `${TARGET_CHAIN.blockExplorers.default.url}/tx/`;
export const BLOCK_EXPLORER_ADDRESS = `${TARGET_CHAIN.blockExplorers.default.url}/address/`;
export const BLOCK_EXPLORER_TOKEN = `${TARGET_CHAIN.blockExplorers.default.url}/token/`;
export const BLOCK_EXPLORER_NFT = `${TARGET_CHAIN.blockExplorers.default.url}/nft/`;

export const LCD_ADDRESS = process.env.GATSBY_LCD_ADDRESS;
export const USDT_ADDRESS = process.env.GATSBY_USDT_ADDRESS;
export const FRACTIONS_SALE_ADDRESS = process.env.GATSBY_FRACTIONS_SALE_ADDRESS;

export const DEFAULT_CULTURE = `en-US`;

export const lucidaoWebsite = `https://lucidao.com`;
export const governanceWebsite = `https://governance.lucidao.com`;
export const lendWebsite = `https://lend.altr.trade`;

export const getCaller = () => {
  try {
    return new Error().stack?.split(`\n`)[2].trim().split(`@`)[0];
  } catch (Error) {
    return `Unknown`;
  }
};

export const genericErrorCallback = (error) => {
  const caller = getCaller();
  console.error(`${caller} - Error`, error);

  // @altr: API errors are too common, this is too disruptive
  // alert(`Unexpected error!`);
};

export const isBrowser = () => typeof window !== `undefined`;

export function isInAppBrowser(ua) {
  ua = ua.toLowerCase().trim();
  const isIOS = ua.includes(`iphone`) || ua.includes(`ipod`) || ua.includes(`ipad`);
  const isAndroid = ua.includes(`android`);

  // iOS Chrome
  if (ua.includes(`crios`)) {
    return `is_chrome_ios`;
  }

  // Facebook
  if (ua.includes(`fbios`) || ua.includes(`fb_iab`)) {
    return isIOS ? `is_facebook_ios` : isAndroid ? `is_facebook_android` : `is_facebook_unknown`;
  }

  // Instagram
  if (ua.includes(`instagram`)) {
    return isIOS ? `is_instagram_ios` : isAndroid ? `is_instagram_android` : `is_instagram_unknown`;
  }

  // LINE
  if (ua.includes(` line/`)) {
    return isIOS ? `is_line_ios` : isAndroid ? `is_line_android` : `is_line_unknown`;
  }

  // iOS Safari|Twitter|Slack|Discord|etc
  if (isIOS && /safari\/[0-9.]+$/.test(ua)) {
    return `maybe_safari_ios`;
  }

  // Android Chrome|Twitter|Slack|Discord|etc
  if (isAndroid && ua.includes(`chrome`) && /safari\/[0-9.]+$/.test(ua)) {
    return `maybe_chrome_android`;
  }

  return null;
}

export const hasEthereum = isBrowser() && lodashHas(window, `ethereum`);
export const isAndroid = isBrowser() && /(Android)/i.test(navigator.userAgent);
export const isIphone = isBrowser() && /(iPhone|iPod)/i.test(navigator.userAgent);
export const isIpad = isBrowser() && /(iPad)/i.test(navigator.userAgent);
export const isMobile = isBrowser() && (isIphone || isAndroid);
// A mobile browser with ethereum we assume it's Metamask Browser
export const isMetamask = isBrowser() && isMobile && hasEthereum;

export const isIos = () => isBrowser() && (isIphone || isIpad);

export const walletAddressAreEqual = (addr1, addr2) => {
  if (!addr1 || !addr2) {
    return false;
  }
  return addr1.toLowerCase() === addr2.toLowerCase();
};

export function getUniversalISOStringDate(input) {
  const data = new Date(input);
  data.setMinutes(data.getMinutes() - data.getTimezoneOffset());

  return data.toISOString();
}

export function truncateLongId(str, length = 12, savedChars = 4) {
  return str.length > length ? `${str.substring(0, savedChars)}...${str.substring(str.length - savedChars, str.length)}` : str;
}

export function getUniversalDate(input) {
  const myDate = new Date(input);
  myDate.setMinutes(myDate.getMinutes() + myDate.getTimezoneOffset());

  return myDate;
}

export function formatDate(input, format = `DD-MM-YYYY`) {
  const myDate = getUniversalDate(input);
  // var preferredCulture = this.storageService.getLoggedUser().preferredCulture;
  return dayjs(myDate).format(format);
}

export function formatDateTime(input, format = `DD-MM-YYYY / HH:mm:ssZ[Z]`) {
  return dayjs(input).format(format);
}

export function formatTime(input, format = `DD : HH : mm`) {
  return dayjs.duration(input).format(format);
}

export function humanizeDuration(input, prefix = false) {
  return dayjs.duration(input).humanize(prefix);
}

export function millisToGregorian(epoch) {
  let month;
  let day;
  let hour;
  let minute;
  let second;

  second = Math.floor(epoch / 1000);
  minute = Math.floor(second / 60);
  second %= 60;
  hour = Math.floor(minute / 60);
  minute %= 60;
  day = Math.floor(hour / 24);
  hour %= 24;
  month = Math.floor(day / 30);
  day %= 30;
  const year = Math.floor(month / 12);
  month %= 12;

  return { year, month, day, hour, minute, second };
}

export function readableGregorian(gregorianTime, include = [], join = `, `) {
  return Object.keys(gregorianTime)
    .filter((timeKey) => (!include?.[0] || include.includes(timeKey.toLowerCase())) && parseInt(gregorianTime?.[timeKey]) > 0)
    .map((timeKey) => {
      const timeValue = gregorianTime[timeKey];

      if (timeValue === 0) {
        return ``;
      }

      return `${timeValue} ${timeKey}${timeValue > 1 ? `s` : ``}`;
    })
    .join(join);
}

export function formatCurrency(value, minDecimals = 2) {
  return value.toLocaleString(`en-US`, {
    minimumFractionDigits: minDecimals
  });
}

export function parseWriteError(error) {
  if (error.name === `ChainMismatchError`) {
    return `Invalid chain, please switch to ${TARGET_CHAIN.name}`;
  }
  let parsedError = JSON.stringify(error)
    .split(`"`)
    .find((value) => value.includes(`execution reverted`))
    .split(`:`)
    .at(-1);
  while (parsedError[0] === ` `) {
    parsedError = parsedError.slice(1);
  }
  return `${parsedError[0].toUpperCase()}${parsedError.slice(1)}`;
}

export const padNumberTwoDigits = (num) => {
  if (num < 10) {
    return `0${num}`;
  }
  return num;
};

export const hexToRgb = (hex) => {
  hex = hex.replace(/^#/, ``);

  if (hex.length === 3) {
    hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2];
  }

  const r = parseInt(hex.substring(0, 2), 16);
  const g = parseInt(hex.substring(2, 4), 16);
  const b = parseInt(hex.substring(4, 6), 16);

  return { r, g, b };
};

export const checkRightChain = (chainId, setWrongChainOverlayData) => {
  if (chainId !== TARGET_CHAIN.id) {
    setWrongChainOverlayData({
      heading: `Wrong Chain`,
      body: `Please switch to ${TARGET_CHAIN.name}`
    });
    return false;
  }
  return true;
};

export const checkUserIsLoggedIn = (userIsLoggedIn, setOverlayData, setConnectorActive) => {
  if (!userIsLoggedIn) {
    setOverlayData({
      icon: `cross`,
      heading: `You must sign in to proceed.`,
      action: () => {
        setConnectorActive(true);
      },
      actionText: `Sign In`
    });
    return false;
  }
  return true;
};

export const getDotColor = (nftStatus) => {
  switch (nftStatus?.status) {
    case `NoEmail`:
    case `DoKyc`:
    case `WaitingForWhitelisting`:
    case `CollectUserInterest`:
    case `WaitingForSaleStart`:
      return `#0B9BED`;

    case `OnSale`:
    case `OnFractionsSale`:
    case `OnFractionsSaleSuccess`:
    case `SoldOut`:
      return `#3DF988`;

    case `NFTBurned`:
    case `OnFractionsSaleFailed`:
    case `KycRejected`:
      return `#FF8662`;

    default:
      return `#919191`;
  }
};

export const orderbookRootUrl = process.env.GATSBY_ORDERBOOK_URL;

export const getUnixTimestamp = (date = new Date()) => Math.round(new Date(date).getTime() / 1000);

export const getStakingMultiplier = (stakingParams, staker) => {
  const multiplier = 100;

  if (!staker) return multiplier;

  const now = getUnixTimestamp();
  const timeOfLastBoostUpdate = Number(staker.timeOfLastBoostUpdate);
  const timeElapsed = now - timeOfLastBoostUpdate;

  if (
    !stakingParams.tiersDurations ||
    stakingParams.tiersDurations.length === 0 ||
    staker.amountStaked < stakingParams.minStakingBoostAmount ||
    timeOfLastBoostUpdate === 0
  ) {
    return multiplier;
  }

  for (let i = 0; i < stakingParams.tiersDurations.length; i++) {
    if (timeElapsed < stakingParams.tiersDurations[i]) {
      return i === 0 ? multiplier : stakingParams.tiersMultiplier[i - 1];
    }
  }

  return stakingParams.tiersMultiplier[stakingParams.tiersMultiplier.length - 1];
};

export const getStakingTier = (staker) => {
  const stakingTier = [
    { minStakingAmount: 25000, multiplier: 15 },
    { minStakingAmount: 75000, multiplier: 20 },
    { minStakingAmount: 150000, multiplier: 25 },
    { minStakingAmount: 400000, multiplier: 30 }
  ];

  return stakingTier[
    stakingTier.reduce((acc, curr, i) => {
      if (parseInt(formatEther(staker?.amountStaked?.toString() || `0`)) >= curr.minStakingAmount) {
        acc = i + 1;
      }
      return acc;
    }, 0)
  ].multiplier;
};

export const getUsersBoosts = async (raffleId, api) => {
  const earlyBirdsTimeWindow = 2 * 86400;
  const basePercentage = 100;
  const nftOwnerBonus = 110;
  const lendingPlatformUserBonus = 130;
  const kycdBonus = 110;
  const referredBonus = 105;
  const earlyBirdsBonus = 110;
  const minLiquidityProvisioning = 1000;
  const liquidityProvisioningBonus = 110;
  const referringMultiplierNumerator = 5;
  const referringMultiplierDenominator = 3;

  const { raffle } = await GetRaffleById({ raffleId });
  const endEarlyBirdTimestamp = Number(raffle.startTimestamp) + earlyBirdsTimeWindow;
  const [{ purchaseEvents, referrals }, allCustomerReferralCode] = await Promise.all([GetRaffleBuyers({ raffleId }), api.getAllCustomerReferralCode()]);
  const raffleBuyers = purchaseEvents.map((event) => event.buyer).reduce((acc, buyer) => (acc.includes(buyer) ? acc : [...acc, buyer]), []);
  const { users, stakers, stakingParams, loans } = await GetAllRaffleBoosts({ buyerAddresses: raffleBuyers, buyerIds: raffleBuyers });

  const boosts = await Promise.all(
    raffleBuyers.map(async (buyer) => {
      const user = users.find((u) => u.address === buyer);
      const staker = stakers.find((s) => s.address === buyer);
      const userReferralCode = allCustomerReferralCode.find((c) => c.publicAddress === buyer)?.referralCode;
      const referral = referrals.find((r) => r.referralCode === keccak256(userReferralCode));
      const userPurchaseEvents = purchaseEvents.filter((event) => event.buyer === buyer);
      const { data: userLiquidity } = await api.getLiquidityProvisioningByPublicAddress(buyer);

      const nftOwner = user.erc721Owned?.length > 0 ? nftOwnerBonus : basePercentage;
      const lcdStaker = getStakingMultiplier(stakingParams[0], staker) * getStakingTier(staker);
      const lendingPlatformUser = loans?.length > 0 ? lendingPlatformUserBonus : basePercentage;
      const kycd = user?.allowed ? kycdBonus : basePercentage;
      const referrer = Math.floor(Number(referral?.referralAmount || 0n) / referringMultiplierDenominator) * referringMultiplierNumerator + basePercentage;
      const referred = referrals.find((r) => r.referrerOf.includes(buyer))?.referralAmount > 0 ? referredBonus : basePercentage;
      const earlyBirds = userPurchaseEvents.filter((event) => event.timestamp < endEarlyBirdTimestamp)?.length > 0 ? earlyBirdsBonus : basePercentage;
      const liquidityProvider =
        Object.values(userLiquidity).reduce((acc, val) => acc + Number(val.userTvl), 0) >= minLiquidityProvisioning
          ? liquidityProvisioningBonus
          : basePercentage;

      const calculatedBoosts = { nftOwner, lcdStaker, lendingPlatformUser, kycd, referrer, referred, earlyBirds, liquidityProvider };

      return {
        ...calculatedBoosts,
        address: buyer,
        totalBoost: Object.values(calculatedBoosts).reduce((acc, curr) => acc + (curr % basePercentage), basePercentage)
      };
    })
  );
  let prev = { cumulativeCount: 0 };

  return boosts.map((userBoosts) => {
    const ticketCount = purchaseEvents.filter((event) => event.buyer === userBoosts.address).reduce((acc, event) => acc + Number(event.ticketAmount), 0);

    prev = {
      addr: userBoosts.address,
      ticketCount,
      cumulativeCount: prev.cumulativeCount + ticketCount * userBoosts.totalBoost
    };

    return prev;
  });
};

export const pluralize = (word) => {
  // Regular expressions for different pluralization rules
  const pluralRules = [
    [/(ox)$/i, `$1en`], // ox -> oxen
    [/(child)$/i, `$1ren`], // child -> children
    [/(foot)$/i, `feet`], // foot -> feet
    [/(tooth)$/i, `teeth`], // tooth -> teeth
    [/(mouse)$/i, `mice`], // mouse -> mice
    [/(person)$/i, `people`], // person -> people
    [/(man)$/i, `men`], // man -> men
    [/(woman)$/i, `women`], // woman -> women
    [/([^aeiou])y$/i, `$1ies`], // city -> cities
    [/(x|ch|ss|sh)$/i, `$1es`], // box -> boxes, church -> churches
    [/([^aeiou]o)$/i, `$1es`], // potato -> potatoes
    [/f$/, `ves`], // leaf -> leaves
    [/fe$/, `ves`], // wife -> wives
    [/$/, `s`] // default: add 's'
  ];

  // Iterate through the rules and apply the first matching rule
  for (const [rule, replacement] of pluralRules) {
    if (rule.test(word)) {
      return word.replace(rule, replacement);
    }
  }

  // Fallback: add 's'
  return `${word}s`;
};

export const handleImageClick = (event, imageUrl) => {
  if (imageUrl.endsWith(`.webp`)) {
    event.preventDefault();
    const newTab = window.open();
    newTab.document.body.style.margin = `0`;
    newTab.document.body.style.height = `100vh`;
    newTab.document.body.style.display = `flex`;
    newTab.document.body.style.justifyContent = `center`;
    newTab.document.body.style.alignItems = `center`;
    newTab.document.body.innerHTML = `<img src="${imageUrl}" style="max-width: 100%; max-height: 100%;">`;
  }
};

export const getTraderOrderTimestampFromNonce = (nonce) => Number(nonce.slice(4, 14));

export const capitalize = (str) => (str.length > 1 ? str[0].toUpperCase() + str.slice(1) : str.toUpperCase());
