import React from "react";
import styled from "@emotion/styled";
import {
  NFTOverlayCompletion,
  NFTOverlayOfferWhole,
  NFTOverlayProcessing,
  NFTOverlayPurchase,
  NFTOverlayPurchaseClaim,
  NFTOverlayPurchaseFragment,
  NFTOverlayPurchaseFragmentFromOwner,
  NFTOverlayPurchaseFragmentFromSellOrder,
  NFTOverlayRefund,
  NFTOverlaySellFragmentFromBuyOrder,
  NFTOverlayWaitlist,
  NFTOverlayWhitelist,
  NFTOverlayCreateFragmentSellOrder,
  NFTOverlayLeaveEmail
} from "~components";
import useApp from "~hooks/useApp";
import WrongChainOverlay from "~components/Common/WrongChainOverlay";
import NFTOverlayCreateWholeSellOrder from "./NFTOverlayCreateWholeSellOrder";
import NFTOverlayPurchaseWholeFromSellOrder from "./NFTOverlayPurchaseWholeFromSellOrder";
import NFTOverlaySellWholeFromBuyOrder from "./NFTOverlaySellWholeFromBuyOrder";

/** ============================================================================
 * @css
 */

const Container = styled.div`
  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  right: 0;
  pointer-events: none;
  z-index: 100;
`;

const ActiveOverlay = ({ activeOverlay, nft, activeOwner, activeOrder }) => {
  switch (activeOverlay) {
    case `NFTOverlayOfferWhole`:
      return <NFTOverlayOfferWhole nft={nft} activeOwner={activeOwner} />;
    case `NFTOverlayCreateWholeSellOrder`:
      return <NFTOverlayCreateWholeSellOrder nft={nft} />;
    case `NFTOverlayPurchase`:
      return <NFTOverlayPurchase nft={nft} />;
    case `NFTOverlayPurchaseClaim`:
      return <NFTOverlayPurchaseClaim nft={nft} />;
    case `NFTOverlayPurchaseWholeFromSellOrder`:
      return <NFTOverlayPurchaseWholeFromSellOrder nft={nft} activeOrder={activeOrder} />;
    case `NFTOverlaySellWholeFromBuyOrder`:
      return <NFTOverlaySellWholeFromBuyOrder nft={nft} activeOrder={activeOrder} />;
    case `NFTOverlayPurchaseFragment`:
      return <NFTOverlayPurchaseFragment nft={nft} />;
    case `NFTOverlayPurchaseFragmentFromOwner`:
      return <NFTOverlayPurchaseFragmentFromOwner nft={nft} activeOwner={activeOwner} />;
    case `NFTOverlayPurchaseFragmentFromSellOrder`:
      return <NFTOverlayPurchaseFragmentFromSellOrder nft={nft} activeOrder={activeOrder} />;
    case `NFTOverlayCreateFragmentSellOrder`:
      return <NFTOverlayCreateFragmentSellOrder nft={nft} />;
    case `NFTOverlaySellFragmentFromBuyOrder`:
      return <NFTOverlaySellFragmentFromBuyOrder nft={nft} activeOrder={activeOrder} />;
    case `NFTOverlayRefund`:
      return <NFTOverlayRefund nft={nft} />;
    case `NFTOverlayWaitlist`:
      return <NFTOverlayWaitlist guid={nft?.enrichedProduct?.product?.identifier} />;
    case `NFTOverlayWhitelist`:
      return <NFTOverlayWhitelist />;
    case `NFTOverlayLeaveEmail`:
      return <NFTOverlayLeaveEmail />;
    default:
      return null;
  }
};

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTOverlays = ({ nft }) => {
  const { overlayActivityData, activeOverlay } = useApp();

  return (
    <Container>
      <NFTOverlayCompletion />
      <NFTOverlayProcessing />
      <WrongChainOverlay />
      {nft?.enrichedProduct && (
        <ActiveOverlay nft={nft} activeOrder={overlayActivityData?.order} activeOwner={overlayActivityData?.owner} activeOverlay={activeOverlay} />
      )}
    </Container>
  );
};

export default NFTOverlays;
