import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { useAccount } from "wagmi";
import { useApp } from "~hooks";
import { Grid, SVG } from "~components";
import { breakpoint } from "~utils/css";
import { TARGET_CHAIN } from "~utils/helpers";
import SwitchChainButton from "../SwitchChainButton";

/** ============================================================================
 * @css
 */
const Container = styled.article`
  transition: 0.5s var(--cubic-easing) opacity;

  opacity: ${({ active }) => `${active ? `1` : `0`}`};
  pointer-events: ${({ active }) => `${active ? `auto` : `none`}`};

  width: 100vw;
  height: 100svh;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 110;
  display: flex;
  align-items: center;
  background: rgba(240, 240, 240, 0.1);
  backdrop-filter: blur(23px);
`;

const Modal = styled.article`
  grid-column: 1 / -1;
  position: relative;
  background: var(--color-mono-10);
  border: 1px solid var(--color-mono-40);
  border-radius: 1rem;
  backdrop-filter: blur(50px);

  ${breakpoint(`large-tablet`)} {
    grid-column: 5 / span 4;
  }

  .wrongChainOverlay {
    &Close {
      width: 100%;
      height: 3.5rem;
      position: relative;
      padding: 0 0.75rem;
      display: flex;
      align-items: center;
      justify-content: end;

      ${breakpoint(`large-tablet`)} {
        height: 4.75rem;
      }

      button {
        width: 3rem;
        height: 3rem;

        svg {
          width: 0.75rem;
          height: 0.75rem;
        }
      }
    }

    //

    &Content {
      padding: 0 1.5rem 1.75rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;

      ${breakpoint(`large-tablet`)} {
        padding: 0 2.5rem 1.75rem;
      }
    }

    &Fragments {
      // todo: this needs to be an image export
      padding: 2.5rem 2.5rem 2rem 2.5rem;
      border-radius: 0 0 1rem 1rem;
      overflow: hidden;
      background: #382531;
      color: white;

      p {
        margin: 0.5rem 0 1.5rem;
      }
    }

    &Icon {
      width: 64px;
      height: 64px;
      position: relative;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 100%;
      background: var(--color-mono-20);

      svg {
        // todo: adjust height based on icon source
        height: 1.5rem;
      }
    }

    &Heading {
      margin: 1.5rem 0;
    }

    &Body {
      margin: 0 0 2rem;
    }

    &Buttons {
      width: 100%;
    }

    &Button {
      margin-bottom: 0.75rem;

      &Dark {
        background: var(--color-black);
        border-color: var(--color-black);
        color: var(--color-white);

        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background: var(--color-mono-70);
            border-color: var(--color-mono-70);
          }
        }
      }

      &Light {
        @media (hover: hover) and (pointer: fine) {
          &:hover {
            background: var(--color-mono-70);
            border-color: var(--color-mono-70);
          }
        }
      }
    }
  }
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const WrongChainOverlay = () => {
  const { setActiveOverlay, wrongChainOverlayData, setWrongChainOverlayData } = useApp();

  const { chain } = useAccount();

  const reset = () => {
    setActiveOverlay(null);
    setWrongChainOverlayData(null);
  };

  useEffect(() => {
    if (chain?.id === TARGET_CHAIN?.id) {
      reset();
    }
  }, [chain?.id, TARGET_CHAIN?.id]);

  return (
    <Container active={wrongChainOverlayData != null}>
      <Grid>
        <Modal>
          <div className="wrongChainOverlayClose">
            <button type="button" onClick={() => reset()}>
              <SVG svg="cross" fill="white" />
            </button>
          </div>

          <div className="wrongChainOverlayContent">
            <div className="wrongChainOverlayIcon">
              <SVG svg={wrongChainOverlayData?.icon || `cross`} />
            </div>

            {wrongChainOverlayData?.heading && <h3 className="wrongChainOverlayHeading h4">{wrongChainOverlayData.heading}</h3>}

            {wrongChainOverlayData?.body && <p className="wrongChainOverlayBody b2">{wrongChainOverlayData.body}</p>}

            <div className="wrongChainOverlayButtons">
              <SwitchChainButton variant="feature" colorTheme="dark" onSuccess={reset} />
            </div>
          </div>
        </Modal>
      </Grid>
    </Container>
  );
};

export default WrongChainOverlay;
