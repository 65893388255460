import { useEffect, useState } from "react";
import useExternalIntegrations from "~hooks/useExternalIntegrations";

// Todo: this isn't working
const useLcdPrice = () => {
  const [lcdPrice, setLcdPrice] = useState(0);
  const [hasFetched, setHasFetched] = useState(false);
  const { api } = useExternalIntegrations();

  const getLCDPrice = async (decimals = 3) => {
    try {
      const res = await api.getLucidaoTokenPrice();
      return res.toFixed(decimals);
    } catch (e) {
      console.error(`Problem fetching LCD price: `, e);
      return null;
    } finally {
      setHasFetched(true);
    }
  };

  useEffect(() => {
    const fetchLCDPrice = async () => {
      const lcdPriceResponse = await getLCDPrice();
      setLcdPrice(lcdPriceResponse);
    };

    fetchLCDPrice();
  }, []);

  return { lcdPrice, hasFetched };
};

export default useLcdPrice;
