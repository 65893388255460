import { Link } from "gatsby";
import { css } from "@emotion/react";
import { SearchInput, Select, LoadingSpinner, MarketplaceMobileFilters } from "~components";
import { MARKETPLACE_SORT_BY } from "~hooks/useMarketplace";
import { useMemo, useState } from "react";
import cn from "classnames";
import { formatStablecoin, thousandCommas } from "~utils/helpers";
import { Waypoint } from "react-waypoint";
import { useParseTraderOrder } from "~hooks/useParseTraderOrder";

const MarketplaceItems = ({
  productsOnSale,
  totalProducts,
  isLoading,
  setSortBy,
  setFilters,
  filters,
  clearFilters,
  fetchNextPage,
  hasNextPage,
  isFetching,
  isFetchingNextPage,
  isPlaceholderData,
  oracles,
  brands
}) => {
  const [activeGrid, setActiveGrid] = useState(`large`);

  const { getPriceWithFees } = useParseTraderOrder();

  const handleSortChange = (value) => {
    setSortBy(value);
  };

  const handleSearchValueChange = (value) => {
    setFilters((prev) => ({ ...prev, search: value }));
  };

  const options = useMemo(() => Object.entries(MARKETPLACE_SORT_BY).map(([key, val]) => ({ value: val, title: key.split("_").join(" ") })), []);

  return (
    <section className="products">
      <div className="filterMobile">
        <MarketplaceMobileFilters
          searchQuery={filters.search}
          setSearchQuery={handleSearchValueChange}
          filters={filters}
          setFilters={setFilters}
          clearFilters={clearFilters}
          oracles={oracles}
          brands={brands}
        />
        <Select options={options} onChange={handleSortChange} title="Sort by" value={4} />
      </div>
      <div className="filterBar">
        <SearchInput value={filters.search} onChange={handleSearchValueChange} />
        <span className="filterBarCenter">
          <Select options={options} onChange={handleSortChange} title="Sort by" value={4} />
          <span className="caption">{!isFetching || isFetchingNextPage ? totalProducts : `-`} products</span>
        </span>
        <div className="view">
          <button onClick={() => setActiveGrid(`small`)}>
            <i className={cn(`icon`, activeGrid === `small` && `active`)}>small_grid</i>
          </button>
          <button onClick={() => setActiveGrid(`large`)}>
            <i className={cn(`icon`, activeGrid === `large` && `active`)}>large_grid</i>
          </button>
        </div>
      </div>
      <ul className={cn(activeGrid === `small` && `small-grid`)}>
        {(!isFetching || isFetchingNextPage) &&
          productsOnSale.map((product, index) => {
            const priceWithFees = getPriceWithFees(product.order.order);
            const formattedPrice = formatStablecoin(priceWithFees);
            const displayPrice = thousandCommas(parseInt(formattedPrice));
            return (
              <li key={index}>
                <section className="product">
                  <svg width="146" height="20" viewBox="0 0 146 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M3 0.500002L1.71396 0.500002L2.66226 1.36869L17.7189 15.1612C20.5771 17.7794 24.3126 19.2318 28.1887 19.2318L117.811 19.2318C121.687 19.2318 125.423 17.7795 128.281 15.1612L143.338 1.36869L144.286 0.5L143 0.5L3 0.500002Z"
                      stroke="#959595"
                    />
                    <text
                      css={css`
                        font-size: 0.5rem;
                        font-family: "Sohne Leicht", sans-serif;
                        fill: #ffffff;
                      `}
                      x="50%"
                      y="50%"
                      textAnchor="middle"
                      dominantBaseline="middle"
                    >
                      {product.order.order.erc721Token ? `WHOLE` : `FRAGMENTS`}
                    </text>
                  </svg>
                  <div className="productContent">
                    <div className="heading">
                      <strong>{product.oracle.text}</strong>
                      <h3>{product.product.text}</h3>
                      <data className="mobilePrice" value={formattedPrice}>
                        ${displayPrice}
                      </data>
                    </div>
                    <div>
                      <img src={product.nftImage} alt={product.product.text} />
                      <data className="price" value={formattedPrice}>
                        ${displayPrice}
                      </data>
                    </div>
                  </div>
                  <p className="productLink">
                    <Link to={`/marketplace/${product.product.identifier}`}>
                      <span className="visually-hidden">See {product.product.text} details</span>
                    </Link>
                  </p>
                </section>
              </li>
            );
          })}
        {isFetching && <LoadingSpinner isInline={activeGrid === `large`} className="loadingSpinner" />}
      </ul>
      {!isLoading && !isFetchingNextPage && hasNextPage && <Waypoint onEnter={fetchNextPage} topOffset="20%" />}
    </section>
  );
};

export default MarketplaceItems;
