import React from "react";
import styled from "@emotion/styled";
import { Button } from "~components";
import { useApp } from "~hooks";

const Container = styled.article`
  position: relative;
  margin-top: 1.5rem;
`;

/** ============================================================================
 * @component
 * @return {node}
 */
const NFTActionCreateWholeSellOrder = ({ colorTheme = `dark` }) => {
  const { setActiveOverlay } = useApp();

  return (
    <Container>
      <Button
        onClick={() => setActiveOverlay(`NFTOverlayCreateWholeSellOrder`)}
        fluid
        variant="feature"
        colorTheme={colorTheme === `dark` ? `light` : `dark`}
      >
        <span className="b1">Create Sell Order</span>
      </Button>
    </Container>
  );
};

export default NFTActionCreateWholeSellOrder;
