import React from "react";
import { CurrencyTicker, PgWrapper, PgItem } from "~components";

const CurrencyTickerPG = () => (
  <PgWrapper title="CurrencyTicker" itemWidth="100%">
    <PgItem title="Dark increase">
      <div style={{ position: `relative`, padding: `1rem` }}>
        <div
          style={{
            position: `absolute`,
            inset: 0,
            background: `url(https://images.unsplash.com/photo-1607236083122-583e96ea5aac?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1974&q=80)`,
            backgroundSize: `cover`
          }}
        />
        <CurrencyTicker colorTheme="dark" />
      </div>
    </PgItem>
    <PgItem title="Light decrease">
      <div style={{ position: `relative`, padding: `1rem` }}>
        <div
          style={{
            position: `absolute`,
            inset: 0,
            background: `url(https://images.unsplash.com/photo-1620121692029-d088224ddc74?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2664&q=80)`,
            backgroundSize: `cover`
          }}
        />
        <CurrencyTicker colorTheme="light" />
      </div>
    </PgItem>
  </PgWrapper>
);

export default CurrencyTickerPG;
